
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {EntityStandortDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import {v4 as uuid} from 'uuid';
import OrgUnitSelectorLiquor from "@/components/OrgUnitSelectorLiquor.vue";
import {StandortApi} from "@/services/StandortApi";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";

const DataProps = Vue.extend({
  props: {
    value: [],
    multi: Boolean,
    error: String
  }
})
@Component({
  components: {ListItemComponent, OrgUnitSelectorLiquor, Multiselect},
})
export default class LocationChooser extends DataProps {
  @Prop() label: string
  @Prop() typ: string
  @Prop() options: EntityStandortDTO[]
  @Prop() noFilter: boolean
  @Prop() noIcon: boolean
  @Prop() disabled: boolean

  showModal = false

  selectedValue: EntityStandortDTO[] | EntityStandortDTO = []
  selectedId = null
  multiSelect = true
  cbKfz = true
  cbMaterial = true
  cbCustom = true
  cbService = true
  cbStandorte = true
  uuid = uuid()
  searchTexts = []
  locationOpts: EntityStandortDTO[] = []
  locationTree = []

  async created() {
    if (this.options && this.options.length > 0) {
      this.locationOpts = this.options;
    } else {
      switch (this.typ) {
        case "medProdukt":
          this.locationOpts = await this.$store.dispatch('medprodukt/fetchStandorte')
          break;
        case "kfz":
          this.locationOpts = await this.$store.dispatch('kfz/fetchStandorte')
          break;
        case "material":
          this.locationOpts = await this.$store.dispatch('material/fetchStandorte')
          break;
      }
    }

    if (this.typ === "kfz") {
      this.locationOpts = this.locationOpts.filter(location => location.text.trim().toLowerCase() !== 'verlust');
    }


    this.multiSelect = this.$props.multi

    this.init(this.$props.value)
    //
  }

  async init(val) {
    this.$nextTick(() => {
      this.selectedValue = Vue.util.extend(val)
      if (this.selectedValue) {
        this.selectedId = val.id
      }
    });
  }

  async openTree() {
    this.locationTree = await StandortApi.getStandorteTree(this.typ);
    this.showModal = true
  }

  treeClicked($e) {
    this.selectedValue = $e
    this.selectedId = this.selectedValue.id

    this.$emit("input", $e)

  }

  sendChange(event) {
    this.$emit("input", this.selectedValue)
    this.selectedId = this.selectedValue?.id
  }


  filter(pk: EntityStandortDTO) {
    let res = true
    this.searchTexts.forEach((t) => {
      if (pk['filterText'].toLowerCase().includes(t.toLowerCase()) && res) {
        res = true
      } else {
        res = false
      }
    })

    return res
  }


  get getOptions() {
    return this.locationOpts
        .filter((pK: EntityStandortDTO) => (!this.cbKfz) ? pK.kfz === null : true)
        .filter((pK: EntityStandortDTO) => (!this.cbService) ? pK.outsourcing === null : true)
        .filter((pK: EntityStandortDTO) => (!this.cbMaterial) ? pK.material === null : true)
        .filter((pK: EntityStandortDTO) => (pK.orgUnit && !pK.customLocation) ? this.cbStandorte : true)
        .filter((pK: EntityStandortDTO) => (pK.customLocation) ? this.cbCustom : true)
        .sort((a: EntityStandortDTO, b: EntityStandortDTO) => {
          const aIsCustom = a.customLocation || a.searchText === 'cbCustom';
          const bIsCustom = b.customLocation || b.searchText === 'cbCustom';

          const aAbstract = a.abstract;
          const bAbstract = b.abstract;

          if (aAbstract && !bAbstract) return 1;
          if (bAbstract && !aAbstract) return -1;

          if (aIsCustom && !bIsCustom) return 1;
          if (!aIsCustom && bIsCustom) return -1;

          return a.searchText.localeCompare(b.searchText);
        });
  }

  @Watch('value')
  public watchValue(newValue) {
    this.init(newValue)
  }
}
