
import {Component, Prop, Watch} from 'vue-property-decorator';
import {MedProduktDTO, MedProduktOutsourcingDTO, OutsourcingCategoryDTO, OutsourcingDTO, ResponseDTO} from "@/model/dto";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import Multiselect from "@/libs/multiselect";
import {MedProduktOutsourcing} from "@/model/Outsourcing";
import {OutsourcingApi} from "@/services/OutsourcingApi";
import MedProduktChooser from "@/components/MedProduktChooser.vue";
import {MedProduktHelper} from "@/model/MedProdukt";

@Component({
  components: {MedProduktChooser, Multiselect},
})
export default class MedProduktOutsourcingEditModal extends mixins<GenericMixin<MedProduktOutsourcingDTO, MedProduktOutsourcingDTO>>(GenericMixin) {
  data: MedProduktOutsourcingDTO = new MedProduktOutsourcing()
  error: MedProduktOutsourcingDTO = new MedProduktOutsourcing()

  medproduktList: MedProduktDTO[] = []
  categories: OutsourcingCategoryDTO[] = []
  outsourcingOpts: OutsourcingDTO[] = []

  @Prop() private value!: MedProduktOutsourcingDTO;
  @Prop() private id!: string;

  async init(newValue) {
    this.data = new MedProduktOutsourcing()

    if (newValue != null) {
      this.data = structuredClone(newValue)
    }


    this.medproduktList = await this.$store.dispatch("medprodukt/fetchMedizinprodukteOu")

    if (this.value && this.value.medProdukt) {
      if (this.value && this.value.medProdukt) {
        this.medproduktList = MedProduktHelper.childOrParentFilter(this.value.medProdukt, this.medproduktList)
      }
    }
  }


  async created() {
    this.init(this.$props.value)
    this.categories = await this.$store.dispatch('outsourcing/fetchKategorien')
    this.outsourcingOpts = await this.$store.dispatch('medprodukt/fetchOutsourcing')

  }

  @Watch('value')
  public watchValue(newValue) {
    this.init(newValue)
  }

  close() {
    this.$bvModal.hide(this.$props.id)
  }


  save() {
    const p = OutsourcingApi.putMedProduktOutsourcing(this.data);
    this.handleApiRequest(p, true, true).then((responseDTO: ResponseDTO) => {
      if (responseDTO.data != undefined) {
        this.$emit('input', this.data)
        this.$bvModal.hide(this.$props.id)
        this.$emit('change', this.data)
      }
    });
  }


  get categorieOpts() {
    return this.categories
        //.filter(cat => cat.typ == 2)
  }

}
