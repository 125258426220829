
import {Component} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {Route} from "vue-router";
import Multiselect from '@/libs/multiselect'
import {Event} from "@/model/Constants";
import {MaterialArtDTO, Permission, PermissionModul} from "@/model/dto";
import {bus} from '@/main';
import {_} from 'vue-underscore';
import MaterialStandortKorrekturView from "@/views/material/MaterialStandortKorrekturView.vue";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import DateForm from "@/components/DateForm.vue";
import {HistoryEvent} from "@/model/Shared";
import NavMixin from "@/views/NavMixin.vue";
import {mixins} from "vue-class-component";


@Component(
    {
      components: {DateForm, DatatableButtonHeader, MaterialStandortKorrekturView, Multiselect},
      watch: {
        '$route'(val: Route) {
          this.handleRoute()
        }
      }
    }
)
export default class MaterialNavComponent extends mixins(NavMixin) {
  materialArtOpts: MaterialArtDTO[] = []
  materialArtSelected: MaterialArtDTO[] = []
  archive = false
  tableRoute = false
  routeName = null
  fileCategories = []
  dateTypes = []
  onChangeDebounced = null
  onChangeDebounceMaterial = null

  navBar = []
  navBarSettings = []

  async created() {
    this.fillNavbar(
        ['materiallist', 'materialdatelist', 'materialfilelist', 'materialtasktemplatelist', 'materialtasklist'],
        this.navBar)

    this.fillNavbar(
        ['materialkategorielist', 'materialklassenlist', 'materialhersteller',
          'materialfilecategory', 'materialfilecategorycatalog',
          'materialdatesettings', 'materialdatetypcatalog',
          'materialstandorte', 'materialformlist'], this.navBarSettings)

    this.handleRoute()

    this.fileCategories = await this.$store.dispatch("material/fetchDateiKategorienOu")
    this.dateTypes = await this.$store.dispatch("material/fetchDateTypesOu")
    this.onChangeDebounced = _.debounce(() => {
      bus.$emit(Event.entityTaskFilterChanged)
    }, 1500)
    this.onChangeDebounceMaterial = _.debounce(() => {
      bus.$emit(Event.materialArtFilterChanged)
    }, 500)


  }

  get fileCategoryOpts() {
    let arr = this.fileCategories.map((ufk) => ufk.fileCategory).sort((a, b) => a.name.localeCompare(b.name))
    return [...new Map(arr.map((item) => [item["id"], item])).values()];
  }

  get fileCategory() {
    return this.$store.state.material.filter.fileCategory
  }

  set fileCategory(val) {
    this.$store.state.material.filter.fileCategory = val
    bus.$emit(Event.materialFileCategoryFilter, val)
  }

  get dateTyp() {
    return this.$store.state.material.filter.dateType
  }

  set dateTyp(val) {
    this.$store.state.material.filter.dateType = val
    bus.$emit(Event.materialDateCategoryFilter, val)
  }

  get isFormTabActive(): boolean {
    const regexFormTab = /^\/.*\/edit\/.*\/8(?:[/?#]|$)/;
    return regexFormTab.test(this.$route.path);
  }

  get isTaskTabActive(): boolean {
    const regexTaskTab = /^\/.*\/edit\/.*\/7(?:[/?#]|$)/;
    return regexTaskTab.test(this.$route.path);
  }

  get isHygiene(): boolean {
    const regexTaskTab = /^\/.*\/edit\/.*\/11(?:[/?#]|$)/;
    return regexTaskTab.test(this.$route.path);
  }

  get isDateFormActive(): boolean {
    return this.routeName.includes('tasklist') || this.isFormTabActive || this.isTaskTabActive || this.isHygiene
  }

  handleRoute() {
    this.routeName = this.$route.name
  }
  get status() {
    return this.$store.state.task.entityTaskRequestFilter.statusId
  }
  set status(val) {
    this.$store.state.task.entityTaskRequestFilter.statusId = val
    this.onChangeDebounced()
  }

  get taskStatusOptions() {
    return this.$store.state.task.options;
  }

  get datefrom() {
    return this.$store.state.task.entityTaskRequestFilter.dateFrom
  }

  set datefrom(val) {
    this.$store.state.task.entityTaskRequestFilter.dateFrom = val
    this.onChangeDebounced()
  }

  get dateto() {
    return this.$store.state.task.entityTaskRequestFilter.dateTo
  }

  set dateto(val) {
    this.$store.state.task.entityTaskRequestFilter.dateTo = val
    this.onChangeDebounced()
  }

  get modul() {
    return this.$store.state.task.entityTaskRequestFilter.modulId
  }

  set modul(val) {
    this.$store.state.task.entityTaskRequestFilter.modulId = val
    this.onChangeDebounced()
  }


  async mounted() {
    await this.$store.dispatch("material/fetchMaterialArt")
    this.materialArtSelected = this.$store.getters['material/getMaterialArtFilter'];

  }


  async materialArtChanged() {
    await this.$store.dispatch('material/materialArtFilterUpdate', this.materialArtSelected);
    this.onChangeDebounceMaterial()
  }

  get filterOptions() {
    return this.$store.getters['material/getMaterialArts']
  }

  history() {
    bus.$emit(Event.history, new HistoryEvent('/material/history'))
  }

  get historyPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Material_Settings, Permission.History)
  }
}
