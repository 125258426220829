
import {Component, Vue} from "vue-property-decorator";
import {CampusResultDTO} from "@/model/dto";
import {DateHelper} from "@/model/Date";
import {FobiApi} from "@/services/FobiApi";


@Component(
    {
      computed: {
        DateHelper() {
          return DateHelper
        }
      },
      components: {}
    }
)
export default class CampusOverviewTable extends Vue {
  data: CampusResultDTO = null;

  async created() {
    this.data = await FobiApi.getCampusOverview()
    this.$root.$emit('loadingDone')
  }
  get sortedUsers() {
    if (!this.data || !this.data.users) return [];
    // Erstelle eine sortierte Kopie der Benutzerliste für die Anzeige
    return [...this.data.users].sort((a, b) => {
      const lastNameA = a.formattedLong.split(" ")[0].toLowerCase();
      const lastNameB = b.formattedLong.split(" ")[0].toLowerCase();
      return lastNameA.localeCompare(lastNameB);
    });
  }
}
