
import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {AbstractDTO, EntityTaskDTO, EntityTaskTemplateDTO, GenericError} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import LabelComponent from "@/components/LabelComponent.vue";
import FileUpload from "@/components/FileUpload.vue";
import HtmlEditor from "@/components/HtmlEditor.vue";
import DropzoneMixin from "@/views/DropzoneMixin.vue";
import PersonenkreisChooser from "@/components/PersonenkreisChooser.vue";
import {Module} from "@/model/Constants";
import {EntityTaskTemplateModus, Task, TaskZModus} from "@/model/Task";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import DateForm from "@/components/DateForm.vue";
import NumberForm from "@/components/NumberForm.vue";
import MedProduktChooser from "@/components/MedProduktChooser.vue";

@Component({
  computed: {
    Module() {
      return Module
    },
    Task() {
      return Task
    },
    EntityTaskTemplateModus() {
      return EntityTaskTemplateModus
    },
    TaskZModus() {
      return TaskZModus
    }
  },
  components: {
    MedProduktChooser,
    NumberForm,
    DateForm,
    ListItemComponent,
    PersonenkreisChooser,
    HtmlEditor,
    FileUpload,
    LabelComponent,
    Multiselect
  }
})
export default class EntityTaskData extends mixins<GenericMixin<EntityTaskDTO, GenericError>>(GenericMixin, DropzoneMixin) {
  @Prop() value: EntityTaskDTO;
  @Prop() error: GenericError;
  @Prop() entityId;

  modul = Module.KFZ

  data: EntityTaskDTO = this.$props.value;

  personenkreisChooserId = 0
  personenkreisOptions = []
  formOptions = []

  kfzOpts = []
  medproduktOpts = []
  materialOpts = []
  uploadKey = 0
  entityTaskTemplates: EntityTaskTemplateDTO[] = []
  scopeOptions = []
  desiRoutines = []
  expand = false

  async created() {
    this.modul = this.$router.currentRoute.meta['module'];
    if (this.entityId) {
      this.data[this.$router.currentRoute.meta['entityKey']] = {id: this.entityId} as AbstractDTO
    }

    this.formOptions = await this.$store.dispatch("form/fetchForms")
    this.formOptions = this.formOptions.filter(form => !form.typ || form.typ == this.modul)
    this.personenkreisOptions = await this.$store.dispatch("user/fetchPersonenkreise")
    this.scopeOptions = await this.$store.dispatch("hygiene/fetchScopes")
    this.desiRoutines = await this.$store.dispatch("hygiene/fetchDesiRoutines")


    if (this.modul == Module.KFZ) {
      this.kfzOpts = await this.$store.dispatch("kfz/fetchKfzOu")
    }
    if (this.modul == Module.MEDPRODUKT) {
      this.medproduktOpts = await this.$store.dispatch("medprodukt/fetchMedizinprodukteOu")
    }
    if (this.modul == Module.MATERIAL) {
      this.materialOpts = await this.$store.dispatch("material/fetchMaterialOu")
    }
  }

  zModusChanged() {
    this.data.zustaendig = []
    this.personenkreisChooserId++
    this.data.checkinBezug = (this.data.zModus == TaskZModus.SCHICHT)
  }


  get workValue() {
    return this.value
  }

  set workValue(val) {
    this.$emit("input", val)
  }

  get zModusOptions() {
    return this.$store.getters['task/getEntityTaskZmodus']
  }

  get isKfz() {
    return this.$route.name.includes("kfz")
  }

  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
    this.uploadKey++
  }
}
