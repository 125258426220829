
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import jquery from "jquery";
import {ApiResponse, ExistingFiles, ObjectEvent, RewisFile} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event, Module} from "@/model/Constants";
import FileUpload from "@/components/FileUpload";
import {KfzDTO, Permission, PermissionModul} from "@/model/dto";
import KfzDataView from "@/views/kfz/KfzDataView.vue";
import {KfzEdit} from "@/model/Kfz";
import {KfzApi} from "@/services/KfzApi";
import KfzFileKfzView from "@/views/kfz/KfzFileKfzView.vue";
import KfzDateKfzView from "@/views/kfz/KfzDateKfzView.vue";
import KfzOutsourcingKfzView from "@/views/kfz/KfzOutsourcingKfzView.vue";
import KfzLocationItemsKfzView from "@/views/kfz/KfzLocationItemsKfzView.vue";
import EinweisungOverviewTable from "@/views/einweisung/EinweisungOverviewTable.vue";
import {EinweisungOverviewRequest} from "@/model/Einweisung";
import EntityTaskTemplateEntityView from "@/views/task/entity/EntityTaskTemplateEntityView.vue";
import EntityTaskListView from "@/views/task/entity/EntityTaskListView.vue";
import EntityFormListServersideView from "@/views/task/entity/EntityFormListServersideView.vue";
import {FileApi} from "@/services/FileApi";
import Fahrtenbuch from "@/views/kfz/components/Fahrtenbuch.vue";
import DesiListView from "@/views/hygiene/DesiListView.vue";


const DataProps = Vue.extend({
  props: {
    id: String
  }
})
@Component({
  computed: {
    FileApi() {
      return FileApi
    },
    Module() {
      return Module
    }
  },
  components: {
    DesiListView,
    EntityFormListServersideView,
    EntityTaskListView,
    EntityTaskTemplateEntityView,
    EinweisungOverviewTable,
    KfzLocationItemsKfzView,
    KfzOutsourcingKfzView,
    KfzDateKfzView,
    KfzFileKfzView,
    KfzDataView,
    FileUpload,
    Fahrtenbuch
  },
  watch: {
    id(val) {
      this.init()
    }
  }
})
export default class KfzEditView extends mixins<GenericMixin<KfzDTO, KfzDTO>>(GenericMixin, DataProps) {
  data = new KfzEdit();
  error = new KfzEdit();
  showTabs = false
  tabIndex = 0
  errorTabs = []
  uploadKey = 0
  basePath = process.env.VUE_APP_REWIS
  showProfilePhotoUploadModal = false
  existingProfilePhoto = null
  einweisungRequest: EinweisungOverviewRequest | null = null

  @Prop({default: 0}) idx: number

  created() {
    this.init();
  }

  init = function () {
    this.$root.$emit('loadingStart')
    this.tabIndex = Number(this.idx)


    const p = KfzApi.getKfz(this.$props.id);
    this.handleApiRequest(p)
    p.finally(() => {
      this.$root.$emit('loadingDone');
      this.showTabs = true

      this.einweisungRequest = new EinweisungOverviewRequest();
      this.einweisungRequest.kfzId = this.$props.id

      if (this.data.profilePhoto) this.existingProfilePhoto = new ExistingFiles([this.data.profilePhoto])
    })
    this.uploadKey += 1
  }

  editProfilePhoto() {
    this.showProfilePhotoUploadModal = true
  }

  profilePhotoFileUploadSuccess(d) {
    let file = new RewisFile(d);

    KfzApi.updatePhoto(this.data.id, file)
        .then((photo) => {
          this.showProfilePhotoUploadModal = false
          this.data.profilePhoto = photo
          this.existingProfilePhoto = new ExistingFiles([this.data.profilePhoto])
        })
        .catch((e) => {
          this.$alert("Beim Upload des Bildes trat ein Fehler auf!");
        });
  }

  profilePhotoFileDelete(d) {
    KfzApi.removePhoto(this.data.id)
        .then((photo) => {
          this.data.profilePhoto = null
          this.existingProfilePhoto = null
        })
        .catch((e) => {
          this.$alert("Beim Löschen des Profilbiles trat ein Fehler auf!");
        });
  }

  archiv() {
    this.$confirm(' Wirklich ' + this.archivTxt.toLowerCase() + '?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      KfzApi.archivKfz(this.data.id)
          .then(() => {
            this.data.archive = !this.data.archive
            bus.$emit(Event.kfzArchived, new ObjectEvent(this.$props.id, this.data));
            bus.$emit(Event.kfzUmzugRequest, this.data);
            this.goBack()
          })
          .catch(() => {
            this.$alert("Beim " + this.archivTxt + " des Fahrzeugs trat ein Fehler auf!");
          })
    });
  }

  onSubmit(goBack: boolean) {
    this.errorTabs = []
    const p = KfzApi.updateKfz(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    var self = this;
    this.handleApiRequest(p, true).then((data) => {
      jquery("#kfztabs .tab-pane").each(
          function (index) {
            if (jquery(this).find('.is-invalid').length > 0) {
              self.errorTabs.push(parseInt(this.getAttribute('tab')));
            }
          }
      );

      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send kfzChanged event')
        bus.$emit(Event.kfzChanged, new ObjectEvent(this.$props.id, data.data));

        if (goBack) {
          this.goBack()
        }
      }
    })
  }

  goBack() {
    let queryParam = {}
    if (this.$route.query.archive !== undefined) {
      queryParam = {archive: 1}
    }
    this.$router.push({path: "/kfz/overview", query: queryParam})
  }

  umzug() {
    bus.$emit(Event.kfzUmzugRequest, this.data);
  }

  @Watch('tabIndex')
  public watchValue(newValue, oldvalue) {
    const newRoute = "/kfz/edit/" + this.$props.id + "/" + this.tabIndex
    if (this.$route.fullPath != newRoute) {
      this.$router.replace(newRoute)
    }
  }

  get archivTxt() {
    if (!this.$store.getters.hasPermission(PermissionModul.Kfz, Permission.Archive)) {
      return null
    }
    return this.data.archive ? "Reaktivieren" : "Archivieren"
  }

  get editPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Kfz, Permission.Update)
  }

  get overviewAllPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Kfz, Permission.Overview)
  }
}
