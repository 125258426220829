
import {Component, Prop, Watch} from 'vue-property-decorator';
import {FileCategoryDTO, GenericError, MedProduktDTO, MedProduktFileDTO, Permission, PermissionModul, ResponseDTO} from "@/model/dto";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {MedProduktFile} from "@/model/File";
import Multiselect from "@/libs/multiselect";
import {ExistingFiles, RewisFile} from "@/model/AbstractClasses";
import FileUpload from "@/components/FileUpload.vue";
import {FileApi} from "@/services/FileApi";
import {MedProduktHelper} from "@/model/MedProdukt";
import MedProduktChooser from "@/components/MedProduktChooser.vue";
import DateForm from "@/components/DateForm.vue";
import ManualFileForm from "@/components/ManualFileForm.vue";
import LabelComponent from "@/components/LabelComponent.vue";
import SkipSupervisionForm from "@/views/shared/components/SkipSupervisionForm.vue";
import {Module} from "@/model/Constants";

@Component({
  computed: {
    Module() {
      return Module
    }
  },
  components: {
    SkipSupervisionForm,
    LabelComponent,
    ManualFileForm,
    DateForm,
    MedProduktChooser,
    FileUpload,
    Multiselect
  },
})
export default class MedProduktDateEditModal extends mixins<GenericMixin<MedProduktFileDTO, GenericError>>(GenericMixin) {
  data: MedProduktFileDTO = new MedProduktFile()
  error: GenericError = new GenericError()
  medproduktList: MedProduktDTO[] = []
  fileCategories: FileCategoryDTO[] = []
  existingFile = null;
  showModal = false
  helper = MedProduktHelper
  uploadKey = 0
  @Prop() private value!: MedProduktFileDTO;
  @Prop() private id!: string;

  async init(newValue) {
    this.data = new MedProduktFile()
    this.existingFile = null

    if (newValue != null) {
      this.data = structuredClone(newValue)
      this.error = new GenericError()

      if (this.data.file) {
        this.existingFile = new ExistingFiles([this.data.file])
      }

      this.medproduktList = await this.$store.dispatch("medprodukt/fetchMedizinprodukteOu")

      if (this.value && this.value.medProdukt) {
        this.medproduktList = MedProduktHelper.childOrParentFilter(this.value.medProdukt, this.medproduktList)
      }
    }
  }


  async created() {
    this.init(this.$props.value)
    this.fileCategories = await this.$store.dispatch("medprodukt/fetchDateiKategorienKatalog")
  }

  fileUploadSuccess(d) {
    let file = new RewisFile(d);

    this.data.file = file
    this.$emit('input', this.data)
  }

  fileDeleted(d) {
    this.data.file = null
    this.existingFile = null
    this.$emit('input', this.data)
  }


  @Watch('value')
  public watchValue(newValue) {
    this.init(newValue)
    this.uploadKey++
  }

  @Watch('id')
  public watchId() {
    this.init(this.value)
  }


  close() {
    this.$bvModal.hide(this.$props.id)
  }


  save() {
    const p = FileApi.putMedProduktFile(this.data);
    this.handleApiRequest(p, true, true).then((responseDTO: ResponseDTO) => {
      if (responseDTO.data != undefined) {
        this.$emit('input', this.data)
        this.$bvModal.hide(this.$props.id)
        this.$emit('change', this.data)
      }
    });
  }

  archiv() {
    this.$confirm(' Wirklich ' + (this.data.archive ? 'reaktivieren' : 'archivieren') + '?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      this.data.archive = !this.data.archive
      this.save();
    });
  }


  get medproduktOptList() {
    return this.medproduktList.sort((a, b) => a.name.localeCompare(b.name))
  }


  get fileCategoryOpts() {
    // let arr = this.fileCategories.map((ufk) => ufk.fileCategory).sort((a, b) => a.name.localeCompare(b.name))
    // let uniqueObjArray = [...new Map(arr.map((item) => [item["id"], item])).values()];
    return this.fileCategories.sort((a, b) => a.name.localeCompare(b.name))
  }

  get removePermission() {
    return this.$store.getters.hasPermission(PermissionModul.Medprodukt_Files, Permission.Delete)
  }
  get archivePermission() {
    return this.$store.getters.hasPermission(PermissionModul.Medprodukt_Files, Permission.Archive)
  }

  get downloadPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Medprodukt_Files, (this.data.fileCategories && this.data.fileCategories.find(c => c.confidential))
        ? Permission.Access_Vertraulich : Permission.Access)
  }


}


