
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {AbstractGegenstandDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import {EinweisungConfigGegenstand} from "@/model/Einweisung";
import GegenstandListItemComponent from "@/views/shared/components/GegenstandListItemComponent.vue";
import {GegenstandListItem} from "@/model/Shared";

@Component({
  components: {GegenstandListItemComponent, Multiselect}
})
export default class EinweisungConfigGegenstaendeComponent extends Vue {
  @Prop() private id: string;
  @Prop() private value: AbstractGegenstandDTO[];
  @Prop() private error: any;

  gegenstandList = []
  data: GegenstandListItem[] = []
  parsedError = ""

  filter = {
    kfz: true,
    kfzCategory: true,
    medProdukt: true
  }

  async created() {
    const [
      ewos,
      kfzs,
      materials,
      materialArts,
      kfzCategories
    ] = await Promise.all([
      this.$store.dispatch('medprodukt/fetchEwos'),
      this.$store.dispatch('kfz/fetchKfzOu'),
      this.$store.dispatch('material/fetchMaterialOu'),
      this.$store.dispatch('material/fetchMaterialArt'),
      this.$store.dispatch('kfz/fetchKategorien')
    ]);

    this.gegenstandList = [
      ...ewos.map(m => new GegenstandListItem(m)),
      ...kfzs.map(m => new GegenstandListItem(m)),
      ...materials
          .filter(m => m.einweisungPflicht)
          .map(m => new GegenstandListItem(m)),
      ...materialArts
          .filter(m => m.inEinweisung && m.klasse)
          .map(m => new GegenstandListItem(m)),
      ...kfzCategories
          .filter(m => !m.abstract)
          .map(m => new GegenstandListItem(m))
    ];

    this.init(this.value)
  }

  init(newValue) {
    this.data = []
    if (!this.value) return;

    this.value.forEach(item => {
      if (item.kfz) {
        this.data.push(new GegenstandListItem(item.kfz))
      } else if (item.medProdukt) {
        this.data.push(new GegenstandListItem(item.medProdukt))
      } else if (item.material) {
        this.data.push(new GegenstandListItem(item.material))
      } else if (item.materialKlasse) {
        this.data.push(new GegenstandListItem(item.materialKlasse))
      } else if (item.kfzCategory) {
        this.data.push(new GegenstandListItem(item.kfzCategory))
      }
    })
  }

  removeElement(id) {
    if (Array.isArray(this.data)) {
      this.data = this.data.filter((e) => e.id != id)
    }


    this.changed()
  }

  get filteredGegenstandList() {
    return this.gegenstandList
  }

  changed() {
    let objects = []
    this.data.forEach(item => {
      let mappedData: AbstractGegenstandDTO = new EinweisungConfigGegenstand()
      mappedData.kfz = null
      mappedData.medProdukt = null
      mappedData.material = null
      mappedData.kfzCategory = null
      mappedData.materialKlasse = null

      if (item.type == "kfz") {
        mappedData.kfz = item.dto
      } else if (item.type == "ewo") {
        mappedData.medProdukt = item.dto
      } else if (item.type == "material") {
        mappedData.material = item.dto
      } else if (item.type == "materialArt") {
        mappedData.materialKlasse = item.dto
      } else if (item.type == "kfzCategory") {
        mappedData.kfzCategory = item.dto
      }

      objects.push(mappedData)
    })

    this.$emit("input", objects);
    this.$emit("change", objects);
  }

  @Watch('value')
  public watchValue(newValue) {
    this.init(newValue)
  }
}
