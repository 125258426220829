import Vue from 'vue'
import Vuex from 'vuex'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FileService from '../../services/FileService'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SkillService from '../../services/SkillService'
import {MaterialApi} from "@/services/MaterialApi";
import {StandortApi} from "@/services/StandortApi";
import {MaterialArtDTO, MaterialDTO, OutsourcingDTO} from "@/model/dto";
import {CrudEvent} from "@/model/AbstractClasses";
import {Event} from "@/model/Constants";
import {StoreHelper} from "@/store/StoreHelper";
import {DateApi} from "@/services/DateApi";
import {FileApi} from "@/services/FileApi";

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        materialArtFilter: [],
        materialArt: [],
        materials: [],
        materialsOu: [],
        standorte: [],
        dateTypes: [],
        dateTypesOu: [],
        dateiKategorien: [],
        dateiKategorienOu: [],
        outsourcing: [],
        filter: {
            fileCategory: null,
            dateType: null
        },
    },
    mutations: {
        SET_MATERIAL_ART(state: any, data: any) {
            data = data.sort((a, b) => {
                    if (a.klasse != b.klasse) {
                        return a.klasse > b.klasse ? 1 : -1
                    }
                    return a.name.localeCompare(b.name)
                }
            )
            state.materialArt = data
        },
        SET_MATERIAL_ART_FILTER(state: any, data: any) {
            state.materialArtFilter = data
        },
        SET_MATERIAL(state: any, data: any) {
            state.materials = data
        },
        SET_MATERIAL_OU(state: any, data: any) {
            state.materialsOu = data
        },
        SET_STANDORTE(state: any, data: any) {
            state.standorte = data
        },
        SET_DATE_TYPES(state: any, data: any) {
            state.dateTypes = data
        },
        SET_DATE_TYPES_OU(state: any, data: any) {
            state.dateTypesOu = data
        },
        SET_DATEI_KATEGORIEN(state: any, data: any) {
            state.dateiKategorien = data
        },
        SET_DATEI_KATEGORIEN_OU(state: any, data: any) {
            state.dateiKategorienOu = data
        },
        SET_OUTSOURCING(state: any, data: any) {
            state.outsourcing = data
        },
        resetLocationState(state) {
            state.standorte = []
            state.materials = []
            state.materialsOu = []

            state.materialArt = []
            state.materialArtFilter = []

            state.dateTypes = []
            state.dateTypesOu = []
            state.dateiKategorien = []
            state.dateiKategorienOu = []

            state.outsourcing = []
        }
    },
    actions: {
        bestandUpdate({commit, state}) {
            commit('SET_MATERIAL', [])
        },
        standortUpdate({commit, state}: any) {
            commit('SET_STANDORTE', [])
        },
        materialArtFilterUpdate({commit, state}, value: any) {
            commit('SET_MATERIAL_ART_FILTER', value)
        },
        async fetchMaterialArt({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.materialArt && state.materialArt.length > 0) {
                    resolve(state.materialArt)
                } else {
                    MaterialApi.getMaterialArtList()
                        .then(data => {
                            commit('SET_MATERIAL_ART', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchMaterial({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.materials && state.materials.length > 0) {
                    resolve(state.materials)
                } else {
                    MaterialApi.getMaterialist()
                        .then(data => {
                            commit('SET_MATERIAL', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchMaterialOu({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.materialsOu && state.materialsOu.length > 0) {
                    resolve(state.materialsOu)
                } else {
                    MaterialApi.getMaterialistOu()
                        .then(data => {
                            commit('SET_MATERIAL_OU', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchStandorte({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.standorte && state.standorte.length > 0) {
                    resolve(state.standorte)
                } else {
                    StandortApi.getStandorte("material")
                        .then(data => {
                            commit('SET_STANDORTE', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchDateTypCatalog({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.dateTypes && state.dateTypes.length > 0) {
                    resolve(state.dateTypes)
                } else {
                    DateApi.getDateTypes('material')
                        .then(data => {
                            commit('SET_DATE_TYPES', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchDateTypesOu({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.dateTypesOu && state.dateTypesOu.length > 0) {
                    resolve(state.dateTypesOu)
                } else {
                    DateApi.getMaterialDateTypesOuChain()
                        .then(data => {
                            commit('SET_DATE_TYPES_OU', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchDateiKategorienKatalog({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.dateiKategorien && state.dateiKategorien.length > 0) {
                    resolve(state.dateiKategorien)
                } else {
                    FileApi.getFileCategory('material')
                        .then(data => {
                            commit('SET_DATEI_KATEGORIEN', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchDateiKategorienOu({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.dateiKategorienOu && state.dateiKategorienOu.length > 0) {
                    resolve(state.dateiKategorienOu)
                } else {
                    FileApi.getMaterialFileCategoryOuChain()
                        .then(data => {
                            commit('SET_DATEI_KATEGORIEN_OU', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchOutsourcing({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.outsourcing && state.outsourcing.length > 0) {
                    resolve(state.outsourcing)
                } else {
                    MaterialApi.getOutsourcing()
                        .then(data => {
                            commit('SET_OUTSOURCING', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        crudEvent({commit, state}, event: CrudEvent) {
            switch (event.name) {
                case Event.materialChanged:
                    StoreHelper.update("SET_MATERIAL", state.materials, event, commit)
                    StoreHelper.update('SET_MATERIAL_OU', state.materialsOu, event, commit)
                    if (!event.dto) return;
                    StoreHelper.updateEntityStandorte((!event.dto['materialStandort']), "material", "SET_STANDORTE", state.standorte, event, commit);
                    break;
                case Event.kfzChanged:
                    if (!event.dto) return;
                    StoreHelper.updateEntityStandorte((!event.dto['materialLocation']), "kfz", "SET_STANDORTE", state.standorte, event, commit);
                    break;
                case Event.outsourcingChanged:
                    if (!event.dto) return;
                    StoreHelper.updateEntityStandorte((!event.dto['materialStandort']), "outsourcing", "SET_STANDORTE", state.standorte, event, commit);

                    if (event.dto) {
                        const outsourcingDto = <OutsourcingDTO>event.dto
                        if (!outsourcingDto.materialStandort) {
                            event.id = outsourcingDto.id
                            event.dto = null
                        }
                    }
                    StoreHelper.update("SET_OUTSOURCING", state.outsourcing, event, commit)

                    break;
                case Event.entityStandortUpdate:
                    if (!event.dto) return;
                    StoreHelper.updateEntityStandorte((!event.dto['materialStandort']), null, "SET_STANDORTE", state.standorte, event, commit);
                    break;
                case Event.materialArtChanged:
                    StoreHelper.update("SET_MATERIAL_ART", state.materialArt, event, commit)
                    break;
                case Event.fileCategoryUpdate:
                    if (event.dto && event.dto['typ'] == 5) {
                        StoreHelper.update("SET_DATEI_KATEGORIEN", state.dateiKategorien, event, commit)
                    }
                    break;
                case Event.dateTypUpdate:
                    if (event.dto && event.dto['typ'] == 5) {
                        StoreHelper.update("SET_DATE_TYPES", state.dateTypes, event, commit)
                    }
                    break;
                case Event.materialDateOuUpdate:
                    StoreHelper.update("SET_DATE_TYPES_OU", state.dateTypesOu, event, commit)
                    break;
                case Event.materialFileCategoryOuUpdate:
                    StoreHelper.update("SET_DATEI_KATEGORIEN_OU", state.dateiKategorienOu, event, commit)
                    break;
            }
        },

    },
    getters: {
        getMaterialArts: (state): MaterialArtDTO[] => {
            return state.materialArt
        },
        getMaterialArtFilter: (state): MaterialArtDTO[] => {
            return state.materialArtFilter
        },
        getMaterials: (state): MaterialDTO[] => {
            return state.materials
        },
    },
    modules: {}
}
