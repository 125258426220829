import {
    EntityFileDTO,
    FobiDTO,
    FobiKlasseDTO,
    FobiKombiThemaDTO,
    FobiRequestDTO,
    FobiStatusDTO,
    FobiThemaDTO,
    FobiThemaStundenDTO,
    FobiUserDTO,
    FobiZeitraumAusschlussDTO,
    FobiZeitraumDTO,
    FobiZeitraumThemaKlasseDTO,
    FobiZeitraumUserRowDTO,
    OrgUnitDTO,
    PersonenkreisDTO,
    UserResponseDTO
} from "@/model/dto";
import moment from "moment";
import {DateHelper} from "@/model/Date";
import {AbstractClass, AbstractListItem} from "@/model/AbstractClasses";


export class Fortbildung extends AbstractListItem implements FobiDTO {
    static readonly STATUS_CAMPUS = 4

    archive: boolean | null;
    dateEnd: string | null;
    dateNachweis: string | null;
    dateStart: string | null;
    dozent: string | null;
   
    klassen: FobiKlasseDTO[];
    kommentar: string | null;
    multi: boolean;
    name: string;
    orgUnit: OrgUnitDTO | null;
    ort: string | null;
    status: FobiStatusDTO | null;
    themen: FobiThemaStundenDTO[];
    timeEnd: string | null;
    timeStart: string | null;
    veranstalter: string | null;
    nachweise: EntityFileDTO[];
    kombiThema: FobiKombiThemaDTO | null;
    user: FobiUserDTO[];
    nachweisAbweichend: boolean | null;
    campusUser: UserResponseDTO | null;
    certificateId: string | null;
    certificateStatus: string | null;
    courseId: string | null;
    expiry: string | null;
    issued: string | null;
}

export class FobiThemaStunden extends AbstractClass implements FobiThemaStundenDTO {
   
    stunden: number | null;
    thema: FobiThemaDTO | null;
    tn = false;
}

export class FobiKombiThema extends AbstractClass implements FobiKombiThemaDTO {
   
    name: string;
    orgUnit: OrgUnitDTO | null;
    themen: FobiThemaStundenDTO[];
    archive: boolean | null;
    passOnChilds: boolean;
}

export class FobiThema extends AbstractClass implements FobiThemaDTO {
    archive: boolean | null;
   
    name: string;
    orgUnit: OrgUnitDTO | null;
    passOnChilds: boolean;
    courseId: string | null;
    durationInMinutes: number | null;
    hasTemplate: boolean | null;
    published: boolean | null;
}

export class FobiKlasse extends AbstractClass implements FobiKlasseDTO {
    archive: boolean | null;
   
    name: string;
    orgUnit: OrgUnitDTO | null;
    passOnChilds: boolean;
}

export class FobiZeitraum extends AbstractClass implements FobiZeitraumDTO {
    archive: boolean | null;
    ausschluss: FobiZeitraumAusschlussDTO[] = [];
    end: string;
    gesamt: FobiZeitraumThemaKlasseDTO[] = [];
    klassen: FobiZeitraumThemaKlasseDTO[] = [];
    name: string;
    orgUnit: OrgUnitDTO | null;
    prevMinus: boolean;
    prevPlus: boolean;
    prevZeitraum: FobiZeitraumDTO | null;
    start: string;
    themen: FobiZeitraumThemaKlasseDTO[] = [];
    themaSpezGesamt: boolean;
    passOnChilds: boolean;
}

export class FobiZeitraumThemaKlasse extends AbstractClass implements FobiZeitraumThemaKlasseDTO {
   
    klasse: FobiKlasseDTO | null;
    maxGesamt: number | null;
    personenkeis: PersonenkreisDTO[];
    soll: number | null;
    themen: FobiThemaDTO[];
    typ: number;
    userIds: number[];

    constructor(typ: number = null) {
        super()
        this.typ = typ;
    }

    tnSoll = false;
}

export class FobiUser extends AbstractClass implements FobiUserDTO {
   
    user: UserResponseDTO | null;
    files: EntityFileDTO[];
    status: FobiStatusDTO | null;
}

export class FobiOverview {
    overviewData: FobiZeitraumUserRowDTO[];

    constructor(zeitraumId: number) {
        this.zeitraumId = zeitraumId;
    }

    zeitraumId: number
    completeUserIds: number[] = []
    uncompleteUserIds: number[] = []
}

export class FobiUserOverview {

    constructor(userId: number) {
        this.userId = userId;
    }

    userId: number
    completeZeitraeume: FobiZeitraumDTO[] = []
    uncompleteZeitraeume: FobiZeitraumDTO[] = []
}

export class FobiZeitraumAusschluss extends AbstractClass  implements FobiZeitraumAusschlussDTO {
    grund: string | null;
    user: UserResponseDTO;
    zeitraumId: number | null;
}

export class FobiRequest extends AbstractClass implements FobiRequestDTO {
    archive: boolean | null;
    dateFrom: string | null;
    dateTo: string | null;
    statusId: number | null;

    ouId: number | null;
    userId: number | null;


    constructor() {
        super()
        this.dateFrom = moment().startOf('year').format(moment.HTML5_FMT.DATE);
        this.dateTo = moment().endOf('year').format(moment.HTML5_FMT.DATE);
        this.statusId = 1
    }

    formDataToRequest(self = new FobiRequest()) {
        self.ouId = null
        self.userId = null

        self.archive = this.archive
        self.statusId = this.statusId
        self.dateFrom = DateHelper.getSqlDate(this.dateFrom)
        self.dateTo = DateHelper.getSqlDate(this.dateTo)

        return self
    }

    countOnly: boolean;
    ouChain: boolean;
    list = false;
}