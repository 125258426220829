
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {GenericError, OrgUnitSettingsDTO} from "@/model/dto";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import LabelComponent from "@/components/LabelComponent.vue";
import {ApiResponse} from "@/model/AbstractClasses";
import VivendiDienstOuMapList from "@/views/diva/components/VivendiDienstOuMapList.vue";
import {OrgUnitApi} from "@/services/OrgUnitApi";
import Multiselect from "@/libs/multiselect";

@Component({
  computed: {},
  components: {
    VivendiDienstOuMapList,
    LabelComponent,
    Multiselect
  }
})
export default class OrgUnitSettingsView extends mixins<SimpleViewMixin<OrgUnitSettingsDTO, GenericError>>(SimpleViewMixin) {
  syncDto: OrgUnitSettingsDTO = null;
  error: GenericError = new GenericError()


  permissionGroupOptions = []


  async created() {
    await this.init()
    this.permissionGroupOptions = this.$store.getters.getConstants.permissionGroups
    this.$root.$emit('loadingDone')
  }

  async init() {
    this.syncDto = await OrgUnitApi.getSyncDto()
  }

  async saveSyncDto() {
    const promise = OrgUnitApi.saveSyncDto(this.syncDto)
    this.handleApiRequest(promise, true)
        .then((data) => {
          if (data instanceof ApiResponse && data.data !== undefined) {
            this.syncDto = data.data
          }
        })
        .catch((error) => {
          this.init()
          this.$root.$emit('alert', 'Fehler beim Speichern der Standorteinstellungen', 'error')
        })
  }
}
