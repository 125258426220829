
import {Component} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import jquery from "jquery";
import {v4 as uuid} from 'uuid';
import '@/scripts/dataTables_German'
import {func} from '@/scripts/scripts'
import {Event} from "@/model/Constants";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {DateHelper} from "@/model/Date";
import {Permission, PermissionModul} from "@/model/dto";


@Component({
  components: {DatatableButtonHeader}
})
export default class UserQualificationReminderListView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  error = null;
  tableId = 'dTable_' + uuid();
  data = null
  key = 0
  includeChilds = false

  destroyed() {
    this.unregisterEvents()
  }

  mounted() {
    this.init()
  }

  init() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this

    if (this.table) {
      self.unregisterEvents()
      jquery('#' + this.tableId).DataTable().clear().destroy();
      jquery('#' + this.tableId + ' tbody').empty();
      //  jquery('#' + this.tableId + ' thead').empty();
    }

    const buttons = (this.editPermission) ? [
      {
        className: 'text-secondary bg-white',
        text: '<i class="fas fa-pen fa-xs" />',
        extend: 'selectedSingle',
        action: function (e, dt, node, config) {
          let id = table.row({selected: true}).id()
          self.editUser(id)
        },
      },
    ] : []
    this.addButton(
        PermissionModul.User, Permission.Overview_Chain,
        {
          titleAttr: 'Untergeordnete Standorte ' + (self.includeChilds ? 'NICHT ' : '') + 'mit einbeziehen',
          className: 'text-secondary bg-white',
          text: '<i style="font-size: 1.5rem;" class="' + (self.includeChilds ? 'fas' : 'fal') + ' fa-list-tree"/>',
          action: function (e, dt, node, config) {
            self.includeChilds = !self.includeChilds
            self.init()
          },
        }, buttons)


    this.registerEvents([Event.userQualificationChanged])
    const dateHelper = DateHelper

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: '/user/userqualification/reminder'  + (self.includeChilds ? '/withchilds' : ''),
        type: 'GET',
      }),
      responsive: false,
      stateSave: false,
      order: [[5, 'desc']],
      'columnDefs': [
        {'orderData': [6], 'targets': [5]},
        {'sortable': true, 'searchable': false, 'visible': false, 'type': 'num', 'targets': [6]}
      ],
      scrollX: true,
      rowId: "user.id",
      columns: [
        {data: 'user.username', class: 'center'},
        {data: 'user.lastname', class: 'center'},
        {data: 'user.firstname', class: 'center'},
        {data: 'qualification.name', class: 'center'},
        {
          class: 'center text-center',
          data: null,
          render: function (data, type, row) {
            return DateHelper.parseDate(data.validTo)
          }
        },
        {
          class: 'text-center',
          data: null,
          render: function (data, type, row) {
            return data.icon + "<span class='d-none'>" + data.status + "</span>"
          }
        },
        {
          class: 'center',
          data: 'statusNumeric'
        },
      ],
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: func.addDefaultButtons(buttons)
    })
    func.datatableInitComplete(table)
    self.table = table

    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function () {
      let rowId = jquery(this).attr('id')
      self.editUser(rowId)
    })
  }

  editUser(id) {
    if (!this.$store.getters.hasPermission(PermissionModul.User, Permission.Update)) {
      return;
    }

    this.$router.push({path: "/user/edit/" + id})
  }

  get editPermission() {
    return this.$store.getters.hasPermission(PermissionModul.User, Permission.Update)
  }
}
