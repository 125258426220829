import { render, staticRenderFns } from "./EinweisungGegenstandComponent.vue?vue&type=template&id=197a6e81&scoped=true&"
import script from "./EinweisungGegenstandComponent.vue?vue&type=script&lang=ts&"
export * from "./EinweisungGegenstandComponent.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "197a6e81",
  null
  
)

export default component.exports