import {
    AbstractDTO,
    EwmpgFileCategoryDTO,
    EwmpgFileCategoryEwoDTO,
    EwoDTO,
    EwoFileDTO,
    EwoGeraeteartDTO,
    FileCategoryDTO,
    FileDTO,
    FileLexCategoryDTO,
    FileLexDTO,
    FileLexTreeDTO,
    KfzCategoryDTO,
    KfzFileCategoryDTO,
    KfzFileDTO,
    KfzResponseDTO,
    ManualFileDTO,
    MaterialArtDTO,
    MaterialDTO,
    MaterialFileCategoryDTO,
    MaterialFileDTO,
    MedProduktDTO,
    MedProduktEwmpgFileCategoryDTO,
    MedProduktFileCategoryDTO,
    MedProduktFileDTO,
    OrgUnitDTO,
    PersonenkreisDTO,
    UserFileCategoryDTO,
    UserFileDTO,
    UserResponseDTO,
    WachenCloudDateDTO,
    WachenCloudDTO,
    WachenCloudTransferDTO
} from "@/model/dto";
import {AbstractClass, AbstractListItem} from "@/model/AbstractClasses";

export class UserFileCategory extends AbstractClass implements UserFileCategoryDTO  {
    fileCategory: FileCategoryDTO;
   
    personenkeis: PersonenkreisDTO[] = [];
    confidential: boolean;
    passOnChilds: boolean;
    orgUnit: OrgUnitDTO | null;
    audit: boolean | null;
}

export class UserFileCategoryError  {
    fileCategory: string;
    personenkeis: string;
    confidential: string;
    passOnChilds: string;
}

export class KfzFileCategory extends AbstractClass implements KfzFileCategoryDTO  {
    confidential: boolean;
    fileCategory: FileCategoryDTO | null;
    forAll: boolean;
   
    kfz: KfzResponseDTO[];
    kfzCategory: KfzCategoryDTO[];
    passOnChilds: boolean;
    foreignElement: boolean;
    orgUnit: OrgUnitDTO | null;
    audit: boolean | null;
}

export class KfzFileCategoryError  {
    fileCategory: string;
    confidential: string;
    passOnChilds: string;
    forAll: string;
    kfzCategory: string;
    kfz: string;
}

export class EwmpgFileCategory extends AbstractClass implements EwmpgFileCategoryDTO  {
    confidential: boolean;
    ewo: EwoDTO[];
    fileCategory: FileCategoryDTO | null;
    audit: boolean | null;
   
}


export class EwmpgFileCategoryEwo extends AbstractClass implements EwmpgFileCategoryEwoDTO  {
    ewo: EwoDTO;
    fileCategory: FileCategoryDTO | null;
   
}

export class MedProduktFileCategory extends AbstractClass implements MedProduktFileCategoryDTO  {
    confidential: boolean;
    fileCategory: FileCategoryDTO | null;
    foreignElement: boolean;
    geraeteart: EwoGeraeteartDTO[];
   
    medProdukt: MedProduktDTO[];
    passOnChilds: boolean;
    orgUnit: OrgUnitDTO | null;
    audit: boolean | null;
}


export class UserFile extends AbstractClass implements UserFileDTO{
    file: FileDTO;
    fileCategory: FileCategoryDTO;
    frontendRef: number | null;
   
    status: string | null;
    statusNumeric: number | null;
    user: UserResponseDTO;
    validUntil: string | null;
    categoryMap: UserFileCategoryDTO[];
    comment: string | null;
    fileId: number | null;
    icon: string | null;
    uploadDate: string | null;
    manualFile: ManualFileDTO | null;
    downloadFile: FileDTO | null;
    fileCategories: FileCategoryDTO[] | null;
    archive: boolean;

    constructor() {
        super()
        this.archive = false
    }

    skip: boolean;
    skipReason: string | null;
    skipUntil: string | null;
    originalGesehen: boolean | null;
}

export class UserFileError {
    file: string;
    fileCategory: string
    user: string
    validUntil: string
    comment: string
}

export class KfzFile extends AbstractClass implements KfzFileDTO{
    file: FileDTO;
    fileCategory: FileCategoryDTO;
    frontendRef: number | null;
   
    status: string | null;
    statusNumeric: number | null;
    kfz: KfzResponseDTO;
    validUntil: string | null;
    categoryMap: KfzFileCategoryDTO[];
    comment: string | null;
    fileId: number | null;
    icon: string | null;
    uploadDate: string | null;
    manualFile: ManualFileDTO | null;
    downloadFile: FileDTO | null;
    fileCategories: FileCategoryDTO[] | null;
    archive: boolean;

    constructor() {
        super()
        this.archive = false
    }

    skip: boolean;
    skipReason: string | null;
    skipUntil: string | null;
    originalGesehen: boolean | null;
}

export class ManualFile extends AbstractClass implements ManualFileDTO{
    file: FileDTO | null;
    hersteller: string | null;
   
    modell: string | null;
    standVon: string | null;
    version: string | null;
    searchText: string | null;
    fileCategory: FileCategoryDTO | null;
    archive: boolean;
    fileCategories: FileCategoryDTO[] | null;
}


export class KfzFileError {
    file: string;
    fileCategory: string
    kfz: string
    validUntil: string
    comment: string
}

export class EwoFile extends AbstractClass implements EwoFileDTO {
    file: FileDTO;
    fileCategory: FileCategoryDTO;
    frontendRef: number | null;
   
    validUntil: string | null;
    ewo: EwoDTO | null;
    fileCategories: FileCategoryDTO[] | null;
    comment: string | null;
    standVon: string | null;
    version: string | null;
}

export class EwoFileError {
    file: string;
    fileCategory: string
    ewo: string
    validUntil: string
}


export class MedProduktFile extends AbstractClass implements MedProduktFileDTO {
    downloadFile: FileDTO;
    file: FileDTO | null;
    fileCategory: FileCategoryDTO | null;
    frontendRef: number | null;
   
    medProdukt: MedProduktDTO | null;
    status: string | null;
    statusNumeric: number | null;
    validUntil: string | null;
    childs: MedProduktFileDTO[];
    categoryMap: MedProduktEwmpgFileCategoryDTO[];
    fileId: number | null;
    uploadDate: string | null;
    comment: string | null;
    icon: string | null;
    manualFile: ManualFileDTO | null;
    fileCategories: FileCategoryDTO[] | null;
    archive: boolean;

    constructor() {
        super()
        this.archive = false
    }

    skip: boolean;
    skipReason: string | null;
    skipUntil: string | null;
    originalGesehen: boolean | null;
}

export class MedProduktFileError {
    file: string;
    fileCategory: string
    medProdukt: string
    validUntil: string
    comment: string
}

export class FileCategory extends AbstractClass implements FileCategoryDTO {
   
    name: string | null;
    orgUnit: OrgUnitDTO | null;
    passOnChilds: boolean | null;
    typ: number | null;
    typString: string | null;
    archive: boolean | null;
    audit: boolean | null;
    confidential: boolean | null;

}

export class MaterialFile extends AbstractClass implements MaterialFileDTO {
    originalGesehen: boolean;
    skip: boolean;
    skipReason: string;
    skipUntil: string;
    archive: boolean;
    categoryMap: MaterialFileCategoryDTO[];
    comment: string | null;
    downloadFile: FileDTO | null;
    file: FileDTO | null;
    fileCategories: FileCategoryDTO[] | null;
    fileCategory: FileCategoryDTO | null;
    fileId: number | null;
    frontendRef: number | null;
    icon: string | null;
   
    manualFile: ManualFileDTO | null;
    material: MaterialDTO | null;
    status: string | null;
    statusNumeric: number | null;
    uploadDate: string | null;
    validUntil: string | null;

}

export class MaterialFileCategory extends AbstractClass implements  MaterialFileCategoryDTO {
    confidential: boolean;
    fileCategory: FileCategoryDTO | null;
    foreignElement: boolean;
   
    materialArt: MaterialArtDTO[];
    orgUnit: OrgUnitDTO | null;
    passOnChilds: boolean;
    audit: boolean | null;
}

export class FileLexCategory extends AbstractClass implements FileLexCategoryDTO {
    name: string | null;
    orgUnit: OrgUnitDTO | null;
    passOnChilds: boolean | null;
    archive: boolean | null;
}

export class FileLex extends AbstractClass implements FileLexDTO {
    archive: boolean | null;
    beschreibung: string | null;
    file: FileDTO | null;
    kategorie: FileLexCategoryDTO | null;
    name: string | null;
    ordnung: string | null;
    orgUnit: OrgUnitDTO | null;
    passOnChilds: boolean | null;
    typ: number | null = 0;
    wert: string | null;
    tree: FileLexTreeDTO | null;
}

export class FileLexTree extends AbstractListItem implements FileLexTreeDTO {
    bezeichnung: string | null;
    childs: FileLexTreeDTO[];
    konsolidierteAnsicht: boolean | null;
    orgUnit: OrgUnitDTO | null;
    parent: FileLexTreeDTO | null;
    passOnChilds: boolean | null;
}

export class WachenCloud extends AbstractClass implements WachenCloudDTO {
    bezeichnung: string | null;
    email: string | null;
    orgUnit: OrgUnitDTO | null;
    personenkreise: PersonenkreisDTO[] = [];
}

export class WachenCloudTransfer extends AbstractClass implements WachenCloudTransferDTO {
    file: FileDTO | null;
    manualFile: ManualFileDTO | null;
    modulId: number | null;
    object: AbstractDTO | null;
    fileCategories: AbstractDTO[] = [];
    originalGesehen: boolean | null;
    name: string | null;
    subObject: AbstractDTO | null;
    dates: WachenCloudDateDTO[];
    validUntil: string | null;
}

export class WachenCloudDate extends AbstractClass implements WachenCloudDateDTO {
    date: string | null;
    dateType: AbstractDTO | null;
}
