
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {FileCategoryDTO, GenericError, KfzCategoryDTO, KfzFileCategoryDTO, KfzResponseDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import {KfzFileCategory} from "@/model/File";
import {FileApi} from "@/services/FileApi";
import PseudoTag from "@/components/PseudoTag.vue";
import KfzView from "@/views/kfz/KfzView.vue";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import SettingsTooltip from "@/components/LabelComponent.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import ChainIconTooltipTableCell from "@/components/ChainIconTooltipTableCell.vue";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {ChainIconTooltipTableCell, SimpletableButtonHeader, SettingsTooltip, KfzView, PseudoTag, Multiselect}
})
export default class KfzFileCategoriesView extends mixins<SimpleViewMixin<KfzFileCategory, GenericError>>(SimpleViewMixin, DataProps) {
  data: KfzFileCategoryDTO = new KfzFileCategory()
  error: GenericError = new GenericError()

  fileCategories: FileCategoryDTO[] = []
  orgUnitFileCategories: KfzFileCategoryDTO[] = []

  kfzOpts: KfzResponseDTO[] = []
  kfzCategoryOpts: KfzCategoryDTO[] = []

  chainCategories: KfzFileCategoryDTO[] = []


  async created() {
    await this.init(KfzFileCategory,
        FileApi.getKfzFileCategoryOu,
        (a) => FileApi.putKfzFileCategoryOu(a),
        (b) => FileApi.removeKfzFileCategoryOu(b))

    this.fileCategories = await this.$store.dispatch("kfz/fetchDateiKategorienKatalog")
    this.orgUnitFileCategories = await FileApi.getKfzFileCategoryOu()

    this.kfzOpts = await this.$store.dispatch("kfz/fetchKfzOu")
    this.kfzCategoryOpts = await this.$store.dispatch("kfz/fetchKategorien")

    this.chainCategories = await this.$store.dispatch('kfz/fetchDateiKategorienOu');
    this.chainCategories = this.chainCategories.filter(cat => (!cat.orgUnit || cat.orgUnit.id != this.$store.getters.getLocation.id))
    this.$root.$emit('loadingDone')
  }

  get getCategories() {
    return this.fileCategories.sort((a, b) => a.name.localeCompare(b.name))
  }

  get getOrgUnitCategories() {
    return this.orgUnitFileCategories.sort((a, b) => a.fileCategory.name.localeCompare(b.fileCategory.name))
  }

  get chainCategoryOpts() {
    //TODO redundanz
    let arr = this.chainCategories.map((ufk) => ufk.fileCategory).sort((a, b) => a.name.localeCompare(b.name))
    let uniqueObjArray = [...new Map(arr.map((item) => [item["id"], item])).values()];
    return uniqueObjArray
  }

  getTagFromChain(id) {
    let arr = []
    this.chainCategories.filter((ufk) => ufk.fileCategory.id == id).some((ufk: KfzFileCategoryDTO) => {
      arr.push(ufk)
    })

    return arr
  }

  get archivTxt() {
    return "Löschen"
  }

}
