import Vue from 'vue'
import Vuex from 'vuex'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FileService from '../../services/FileService'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SkillService from '../../services/SkillService'
import {FileApi} from "@/services/FileApi";
import {EwmpgApi} from "@/services/EwmpgApi";
import {DateApi} from "@/services/DateApi";
import {MedProduktApi} from "@/services/MedProduktApi";
import {StandortApi} from "@/services/StandortApi";
import {CrudEvent} from "@/model/AbstractClasses";
import {Event} from "@/model/Constants";
import {StoreHelper} from "@/store/StoreHelper";
import {OutsourcingDTO} from "@/model/dto";

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        geraeteart: [],
        hersteller: [],
        objektbezeichnung: [],
        softwareversionen: [],
        ewos: [],
        ewosOu: [],
        standorte: [],
        outsourcing: [],
        medProdukte: [],
        medProdukteOu: [],
        dateiKategorien: [],
        country: [],
        dateTypes: [],
        filter: {
            fileCategory: null,
            dateType: null,
        }
    },
    mutations: {
        SET_GERAETEART(state: any, data: any) {
            state.geraeteart = data
        },
        SET_HERSTELLER(state: any, data: any) {
            state.hersteller = data
        },
        SET_OBJEKTBEZEICHNUNG(state: any, data: any) {
            state.objektbezeichnung = data
        },
        SET_SOFTWAREVERSIONEN(state: any, data: any) {
            state.softwareversionen = data
        },
        SET_DATE_TYPES(state: any, data: any) {
            state.dateTypes = data
        },
        SET_EWOS(state: any, data: any) {
            state.ewos = data
        },
        SET_EWOS_OU(state: any, data: any) {
            state.ewosOu = data
        },
        SET_STANDORTE(state: any, data: any) {
            state.standorte = data
        },
        SET_OUTSOURCING(state: any, data: any) {
            state.outsourcing = data
        },
        SET_MED_PRODUKTE(state: any, data: any) {
            state.medProdukte = data
        },
        SET_MED_PRODUKTE_OU(state: any, data: any) {
            state.medProdukteOu = data
        },
        SET_DATEI_KATEGORIEN(state: any, data: any) {
            state.dateiKategorien = data
        },
        SET_COUNTRY(state: any, data: any) {
            state.country = data
        },
        resetLocationState(state) {
            state.medProdukte = []
            state.medProdukteOu = []
            state.ewosOu = []
            state.standorte = []
            state.outsourcing = []
            state.dateiKategorien = []
            state.dateTypes = []
        }
    },
    actions: {
        bestandUpdate({commit, state}) {
            commit('SET_MED_PRODUKTE', [])
        },
        geraeteartUpdate({commit, state}: any) {
            commit('SET_GERAETEART', [])
        },
        herstellerUpdate({commit, state}: any) {
            commit('SET_HERSTELLER', [])
        },
        objektbezeichnungUpdate({commit, state}: any) {
            commit('SET_OBJEKTBEZEICHNUNG', [])
        },
        softwareversionenUpdate({commit, state}: any) {
            commit('SET_SOFTWAREVERSIONEN', [])
        },
        ewoUpdate({commit, state}: any) {
            commit('SET_EWOS', [])
        },
        medProduktUpdate({commit, state}: any) {
            commit('SET_MED_PRODUKTE', [])
        },
        standortUpdate({commit, state}: any) {
            commit('SET_STANDORTE', [])
        },
        fileCategoryOuUpdate({commit, state}: any) {
            commit('SET_DATEI_KATEGORIEN_OU', [])
        },
        async fetchGeraeteart({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.geraeteart && state.geraeteart.length > 0) {
                    resolve(state.geraeteart)
                } else {
                    EwmpgApi.getGeraeteartList()
                        .then(data => {
                            commit('SET_GERAETEART', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchHersteller({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.hersteller && state.hersteller.length > 0) {
                    resolve(state.hersteller)
                } else {
                    EwmpgApi.getHerstellerList()
                        .then(data => {
                            commit('SET_HERSTELLER', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchObjektbezeichnung({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.objektbezeichnung && state.objektbezeichnung.length > 0) {
                    resolve(state.objektbezeichnung)
                } else {
                    EwmpgApi.getObjektbezeichnungList()
                        .then(data => {
                            commit('SET_OBJEKTBEZEICHNUNG', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchSoftwareversionen({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.softwareversionen && state.softwareversionen.length > 0) {
                    resolve(state.softwareversionen)
                } else {
                    EwmpgApi.getSoftwareversionList()
                        .then(data => {
                            commit('SET_SOFTWAREVERSIONEN', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchDateTypCatalog({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.dateTypes && state.dateTypes.length > 0) {
                    resolve(state.dateTypes)
                } else {
                    DateApi.getDateTypes("mpg")
                        .then(data => {
                            commit('SET_DATE_TYPES', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchEwos({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.ewos && state.ewos.length > 0) {
                    resolve(state.ewos)
                } else {
                    EwmpgApi.getEwoList()
                        .then(data => {
                            commit('SET_EWOS', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchEwosOu({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.ewosOu && state.ewosOu.length > 0) {
                    resolve(state.ewosOu)
                } else {
                    EwmpgApi.getEwoListOu()
                        .then(data => {
                            commit('SET_EWOS_OU', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchStandorte({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.standorte && state.standorte.length > 0) {
                    resolve(state.standorte)
                } else {
                    StandortApi.getStandorte("medProdukt")
                        .then(data => {
                            commit('SET_STANDORTE', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchOutsourcing({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.outsourcing && state.outsourcing.length > 0) {
                    resolve(state.outsourcing)
                } else {
                    MedProduktApi.getOutsourcing()
                        .then(data => {
                            commit('SET_OUTSOURCING', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchMedizinprodukte({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.medProdukte && state.medProdukte.length > 0) {
                    resolve(state.medProdukte)
                } else {
                    MedProduktApi.getMedProdukteList()
                        .then(data => {
                            commit('SET_MED_PRODUKTE', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchMedizinprodukteOu({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.medProdukteOu && state.medProdukteOu.length > 0) {
                    resolve(state.medProdukteOu)
                } else {
                    MedProduktApi.getMedProdukteListOu()
                        .then(data => {
                            commit('SET_MED_PRODUKTE_OU', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchDateiKategorienKatalog({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.dateiKategorien && state.dateiKategorien.length > 0) {
                    resolve(state.dateiKategorien)
                } else {
                    FileApi.getFileCategory('mpg')
                        .then(data => {
                            commit('SET_DATEI_KATEGORIEN', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        async fetchCountries({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.country && state.country.length > 0) {
                    resolve(state.country)
                } else {
                    EwmpgApi.getCountries()
                        .then(data => {
                            commit('SET_COUNTRY', data)
                            resolve(data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        crudEvent({commit, state}, event: CrudEvent) {
            switch (event.name) {
                case Event.materialChanged:
                    if (!event.dto) return;
                    StoreHelper.updateEntityStandorte((!event.dto['medProduktStandort']), "material", "SET_STANDORTE", state.standorte, event, commit);
                    break;
                case Event.kfzChanged:
                    if (!event.dto) return;
                    StoreHelper.updateEntityStandorte((!event.dto['mpgLocation']), "kfz", "SET_STANDORTE", state.standorte, event, commit);
                    break;
                case Event.outsourcingChanged:
                    if (event.dto) {
                        StoreHelper.updateEntityStandorte((!event.dto['medProduktStandort']), "outsourcing", "SET_STANDORTE", state.standorte, event, commit);
                    }

                    if (event.dto) {
                        const outsourcingDto = <OutsourcingDTO>event.dto
                        if (!outsourcingDto.medProduktStandort) {
                            event.id = outsourcingDto.id
                            event.dto = null
                        }
                    }
                    StoreHelper.update("SET_OUTSOURCING", state.outsourcing, event, commit)

                    break;
                case Event.entityStandortUpdate:
                    if (!event.dto) return;
                    StoreHelper.updateEntityStandorte((!event.dto['medProduktStandort']), null, "SET_STANDORTE", state.standorte, event, commit);
                    break;
                case Event.medproduktChanged:
                    StoreHelper.update("SET_MED_PRODUKTE", state.medProdukte, event, commit)
                    StoreHelper.update("SET_MED_PRODUKTE_OU", state.medProdukteOu, event, commit)
                    break;
                case Event.fileCategoryUpdate:
                    if (event.dto && event.dto['typ'] == 3) {
                        StoreHelper.update("SET_DATEI_KATEGORIEN", state.dateiKategorien, event, commit)
                    }
                    break;
                case Event.dateTypUpdate:
                    if (event.dto && event.dto['typ'] == 3) {
                        StoreHelper.update("SET_DATE_TYPES", state.dateTypes, event, commit)
                    }
                    break;
            }
        },
    },
    getters: {},
    modules: {}
}
