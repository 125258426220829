
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {ApiResponse, ObjectEvent} from "@/model/AbstractClasses";
import {bus} from '@/main';
import {Event} from "@/model/Constants";
import {EinweisungDTO, Permission, PermissionModul} from "@/model/dto";
import {Einweisung, EinweisungUser} from "@/model/Einweisung";
import {EinweisungApi} from "@/services/EinweisungApi";
import EinweisungDataView from "@/views/einweisung/EinweisungDataView.vue";
import DateForm from "@/components/DateForm.vue";
import EinweisungUserComponent from "@/views/einweisung/components/EinweisungUserComponent.vue";


const DataProps = Vue.extend({
  props: {
    id: String
  }
})
@Component({
  components: {
    EinweisungUserComponent,
    DateForm,
    EinweisungDataView
  },
  watch: {
    id(val) {
      this.init()
    }
  }
})
export default class EinweisungEditView extends mixins<GenericMixin<EinweisungDTO, EinweisungDTO>>(GenericMixin, DataProps) {
  data = new Einweisung();
  error = new Einweisung();
  showTabs = false

  created() {
    this.init();
  }

  init = function () {
    this.$root.$emit('loadingStart')
    this.showTabs = false
    const p = EinweisungApi.getEinweisung(this.$props.id);
    this.handleApiRequest(p)
    p.finally(() => {
      this.$root.$emit('loadingDone');
      this.showTabs = true
    })
  }

  onSubmit(goBack: boolean) {
    const p = EinweisungApi.putEinweisung(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data != undefined) {
        console.log('send einweisungChanged event')
        bus.$emit(Event.einweisungChanged, new ObjectEvent(data.data.id, data.data));
        if (goBack) this.goBack()
      }
    })
  }

  goBack() {
    let queryParam = {}
    if (this.$route.query.archive !== undefined) {
      queryParam = {archive: 1}
    }
    this.$router.push({path: "/einweisung/einweisungtable", query: queryParam})
  }

  archiv() {
    bus.$emit(Event.einweisungArchiveRequest, new ObjectEvent(this.data.id, this.data));
  }

  get archivTxt() {
    return this.data.archive ? "Reaktivieren" : "Archivieren"
  }

  removeUser(idx) {
    this.data.user.splice(idx, 1)
  }

  addUser() {
    this.data.user.push(new EinweisungUser());
  }

  get editPermission() {
    return (this.$store.getters.hasPermission(PermissionModul.Einweisung, Permission.Update))
  }


}
