import { render, staticRenderFns } from "./UmgzugView.vue?vue&type=template&id=2ce8a52e&scoped=true&"
import script from "./UmgzugView.vue?vue&type=script&lang=ts&"
export * from "./UmgzugView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ce8a52e",
  null
  
)

export default component.exports