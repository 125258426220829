import Vue from 'vue'
import Vuex from 'vuex'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import FileService from '../../services/FileService'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SkillService from '../../services/SkillService'
import {CrudEvent} from "@/model/AbstractClasses";
import {DivaApi} from "@/services/DivaApi";
import {Auktion, Suche, SuchenPerson} from "@/model/Diva";

Vue.use(Vuex)

export default {
    namespaced: true,
    state: {
        orgUnits: [],
        informTyp: [
            {text: 'Ersteller', value: Auktion.INFORM_CREATOR},
            {text: 'EvD', value: Auktion.INFORM_EVD},
        ],
        meldeweg: [
            {text: 'E-Mail', value: Auktion.MW_EMAIL},
            {text: 'SMS und E-Mail', value: Auktion.MW_SMS},
        ],
        modus: [
            {text: 'Linear', value: Auktion.MODUS_LINEAR},
            {text: 'Direkt', value: Auktion.MODUS_DIREKT},
        ],
        suchStatus: [
            {text: 'Deaktiviert', value: Suche.STATUS_INACTIVE},
            {text: 'Aktiv', value: Suche.STATUS_ACTIVE},
            {text: 'Beendet', value: Suche.STATUS_END, disabled: true},
        ],
        personStatus: [
            {text: 'Nicht Angefragt', value: SuchenPerson.STATUS_NA, disabled: true},
            {text: 'Angefragt', value: SuchenPerson.STATUS_Angefragt},
            {text: 'Zugesagt', value: SuchenPerson.STATUS_Zugesagt},
            {text: 'Abgelehnt', value: SuchenPerson.STATUS_Abgelehnt},
            {text: 'Ignoriert', value: SuchenPerson.STATUS_Ignoriert},
            {text: 'Kommentar', value: SuchenPerson.STATUS_Kommentar},
            {text: 'Antwort in anderer Suche', value: SuchenPerson.STATUS_OTHER},

        ],
    },
    mutations: {
        SET_ORG_UNITS(state: any, data: any) {
            state.orgUnits = data
        },
        resetLocationState(state) {
            state.orgUnits = []
        },
    },
    actions: {
        async fetchOrgUnits({commit, state}: any) {
            return new Promise(function (resolve, reject) {
                if (state.orgUnits && state.orgUnits.length > 0) {
                    resolve(state.orgUnits)
                } else {
                    DivaApi.getDivaOrgUnits()
                        .then(data => {
                            commit('SET_ORG_UNITS', data)
                            resolve(state.orgUnits)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
            })
        },
        crudEvent({commit, state}, event: CrudEvent) {
          //nothing to do yet
        },
    },
    getters: {},
    modules: {}
}