
import {Component, Vue} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import {EwoGeraeteartDTO, FileCategoryDTO, GenericError, MedProduktDTO, MedProduktEwmpgFileCategoryDTO, MedProduktFileCategoryDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import {MedProduktFileCategory} from "@/model/File";
import {FileApi} from "@/services/FileApi";
import PseudoTag from "@/components/PseudoTag.vue";
import NumberForm from "@/components/NumberForm.vue";
import SimpleViewMixin from "@/views/SimpleViewMixin.vue";
import {MedProduktHelper} from "@/model/MedProdukt";
import MedProduktChooser from "@/components/MedProduktChooser.vue";
import SettingsTooltip from "@/components/LabelComponent.vue";
import SimpletableButtonHeader from "@/components/SimpletableButtonHeader.vue";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import ChainIconTooltipTableCell from "@/components/ChainIconTooltipTableCell.vue";

const DataProps = Vue.extend({
  props: {}
})

@Component({
  components: {
    ChainIconTooltipTableCell,
    ListItemComponent,
    SimpletableButtonHeader, SettingsTooltip, MedProduktChooser, PseudoTag, NumberForm, Multiselect}
})
export default class EwoFileCategoryView extends mixins<SimpleViewMixin<MedProduktFileCategoryDTO, GenericError>>(SimpleViewMixin, DataProps) {
  data: MedProduktFileCategoryDTO = new MedProduktFileCategory()
  error: GenericError = new GenericError()

  fileCategories: FileCategoryDTO[] = []

  medproduktOpts: MedProduktDTO[] = []
  medproduktCategoryOpts: EwoGeraeteartDTO[] = []

  helper = MedProduktHelper

  chainCategories: MedProduktEwmpgFileCategoryDTO[] = []

  async created() {
    this.medproduktCategoryOpts = await this.$store.dispatch("medprodukt/fetchGeraeteart")
    this.medproduktOpts = await this.$store.dispatch("medprodukt/fetchMedizinprodukteOu")
    this.medproduktOpts = this.medproduktOpts.filter(prod => prod.holder == false)
    this.fileCategories = await this.$store.dispatch("medprodukt/fetchDateiKategorienKatalog")

    await this.init(MedProduktFileCategory, FileApi.getMedProduktFileCategories, (a) => FileApi.putMedProduktFileCategory(a), (b) => FileApi.removeMedProduktFileCategory(b))

    this.chainCategories = await FileApi.getMedProduktEwmpgFileCategoryOverviewChain();
    this.chainCategories = this.sort(this.chainCategories.filter(cat => (!cat.orgUnit || cat.orgUnit.id != this.$store.getters.getLocation.id)))
    this.$root.$emit('loadingDone')
  }

  removeMedProduktSelection(medProduktId) {
    this.data.medProdukt = this.data.medProdukt.filter(e => e.id != medProduktId)
  }

  sort(objects) {
    return objects.sort((a, b) => a.fileCategory?.name.localeCompare(b.fileCategory?.name))
  }

  get archivTxt() {
    return "Löschen"
  }


  get getCategories() {
    return this.fileCategories.sort((a, b) => a.name.localeCompare(b.name))
  }


  personenkreise(obj: MedProduktEwmpgFileCategoryDTO) {
    let arr = [...MedProduktHelper.getTagFromFileCategory(obj)]
    return arr
  }

  get chainCategoryOpts() {
    //TODO redundanz
    let arr = this.chainCategories.map((ufk) => ufk.fileCategory).sort((a, b) => a.name.localeCompare(b.name))
    let uniqueObjArray = [...new Map(arr.map((item) => [item["id"], item])).values()];
    return uniqueObjArray
  }


  getTagFromChain(id) {
    let arr = []
    this.chainCategories.filter((chainCat) => chainCat.fileCategory.id == id).some((chainCat: MedProduktEwmpgFileCategoryDTO) => {
      arr.push(...this.personenkreise(chainCat));
    })
    return arr
  }


}
