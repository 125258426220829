
import {Component, Prop} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {Permission, PermissionModul, UserDateDTO} from "@/model/dto";
import {v4 as uuid} from 'uuid';
import jquery from "jquery";
import {func} from '@/scripts/scripts'
import moment from "moment/moment";
import UserDateEditModal from "@/views/user/modal/UserDateEditModal.vue";
import {DateHelper} from "@/model/Date";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import GenericMixin from "@/views/GenericMixin.vue";


@Component(
    {
      components: {DatatableButtonHeader, UserDateEditModal},
    }
)
export default class UserDateView extends GenericMixin {
  @Prop() private id: string;
  tableId = 'dTable_' + uuid();
  data: UserDateDTO | null = null
  modalId = 'user-date-edit-modal'

  includeChilds = false

  edit(id) {
    if (!this.editPermission) {
      return;
    }
    let rowId = "#" + id;
    let table = jquery('#' + this.tableId).DataTable();
    let rowdata = table.row(rowId).data();
    if (rowdata.statusNumeric == -3) {
      this.$alert("Dem ausgewählten Benutzer wurde dieser Termin nicht zugewiesen.")
      return;
    }

    this.data = rowdata
    this.$bvModal.show(this.modalId)
    this.modalId = 'user-date-edit-modal' + uuid()
  }

  updateTable(data) {
    let rowId = "#" + data.user.id;
    let table = jquery('#' + this.tableId).DataTable();
    if (table.row(rowId).data()) {
      table.row(rowId).data(data).draw();
    }
  }

  mounted() {
    this.init()
  }

  init() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this

    if (this.table) {
      jquery('#' + this.tableId).DataTable().clear().destroy();
      jquery('#' + this.tableId + ' tbody').empty();
      //  jquery('#' + this.tableId + ' thead').empty();
    }


    const buttons = (this.editPermission) ? [
      {
        className: 'text-secondary bg-white',
        text: '<i class="fas fa-pen" />',
        extend: 'selectedSingle',
        action: function (e, dt, node, config) {
          let id = table.row({selected: true}).id()
          self.edit(id)
        }
      },
      {
        className: 'text-secondary bg-white',
        titleAttr: 'Betrifft',
        text: '<i class="fas fa-angle-double-right" />',
        action: function (e, dt, node, config) {
          self.openModal()
        }
      }
    ] : []
    this.addButton(
        PermissionModul.User, Permission.Overview_Chain,
        {
          titleAttr: 'Untergeordnete Standorte ' + (self.includeChilds ? 'NICHT ' : '') + 'mit einbeziehen',
          className: 'text-secondary bg-white',
          text: '<i style="font-size: 1.5rem;" class="' + (self.includeChilds ? 'fas' : 'fal') + ' fa-list-tree"/>',
          action: function (e, dt, node, config) {
            self.includeChilds = !self.includeChilds
            self.init()
          },
        }, buttons)

    let table = jquery('#' + this.tableId).DataTable({
          ajax: func.addAuth({
            url: '/user/userdate/datetyp/' + self.id + (self.includeChilds ? '/withchilds' : ''),
            type: 'GET'
          }),
          rowId: "user.id",
          'columnDefs': [
            {'orderData': [7], 'targets': [5]},
            {'sortable': true, 'searchable': false, 'visible': false, 'type': 'num', 'targets': [8]}
          ],
          columns: [
            {
              class: 'center',
              data: 'user.lastname'
            },
            {
              class: 'center',
              data: 'user.firstname'
            },
            {
              class: 'center',
              data: 'user.username'
            },
            {
              class: 'center',
              data: null,
              render: function (data, type, row) {
                return data.date ? moment(data.date).format("DD.MM.YYYY") : ""
              }
            },
            {
              class: 'center text-center',
              data: null,
              render: function (data, type, row) {
                return DateHelper.parseSkip(data)
              }
            },
            {
              class: 'center',
              data: 'skipReason'
            },
            {
              class: 'text-center',
              data: null,
              render: function (data, type, row) {
                return data.icon + "<span class='d-none'>" + data.status + "</span>"
              }
            }, {
              class: 'center',
              data: null,
              render: function (data, type, row) {
                return data.lastreminder ? moment(data.lastreminder).format("DD.MM.YYYY") : ""
              }
            },
            {
              class: 'center',
              data: 'statusNumeric'
            },
          ],
          initComplete: function (settings, json) {
            self.$root.$emit("loadingDone")
          },
          buttons: func.addDefaultButtons(buttons, true)
        }
    )
    func.datatableInitComplete(table)
    self.table = table

    jquery('#' + this.tableId + ' tbody').on('click', 'tr', function () {
      let rowId = jquery(this).attr('id')
      self.edit(rowId)
    })
  }


  get editPermission() {
    return this.$store.getters.hasPermission(PermissionModul.User_Dates, Permission.Update)
  }

  openModal() {
    this.$emit('openModal')
  }

}
