
import {Component, Prop, Watch} from 'vue-property-decorator';
import {GenericError, KfzFileCategoryDTO, KfzFileDTO, KfzResponseDTO, Permission, PermissionModul, ResponseDTO} from "@/model/dto";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {KfzFile} from "@/model/File";
import Multiselect from "@/libs/multiselect";
import {ExistingFiles, RewisFile} from "@/model/AbstractClasses";
import FileUpload from "@/components/FileUpload.vue";
import {FileApi} from "@/services/FileApi";
import DateForm from "@/components/DateForm.vue";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import ManualFileForm from "@/components/ManualFileForm.vue";
import SkipSupervisionForm from "@/views/shared/components/SkipSupervisionForm.vue";
import {Module} from "@/model/Constants";

@Component({
  computed: {
    Module() {
      return Module
    }
  },
  components: {SkipSupervisionForm, ManualFileForm, ListItemComponent, DateForm, FileUpload, Multiselect},
})
export default class KfzDateEditModal extends mixins<GenericMixin<KfzFileDTO, GenericError>>(GenericMixin) {
  data: KfzFileDTO = new KfzFile()
  error: GenericError = new GenericError()
  kfzList: KfzResponseDTO[] = []
  fileCategories: KfzFileCategoryDTO[] = []
  existingFile = null;
  showModal = false
  uploadKey = 0
  @Prop() private value!: KfzFileDTO;
  @Prop() private id!: string;


  init(newValue) {
    this.data = new KfzFile()
    this.existingFile = null

    if (newValue != null) {
      this.data = structuredClone(newValue)
      this.error = new GenericError()

      if (this.data.file) {
        this.existingFile = new ExistingFiles([this.data.file])
      }
    }
  }


  async created() {
    this.init(this.$props.value)
    this.fileCategories = await this.$store.dispatch("kfz/fetchDateiKategorienKatalog")
    this.kfzList = await this.$store.dispatch("kfz/fetchKfzOu")
  }

  fileUploadSuccess(d) {
    let file = new RewisFile(d);
    this.data.file = file
    this.$emit('input', this.data)
  }

  fileDeleted(d) {
    this.data.file = null
    this.existingFile = null
    this.$emit('input', this.data)
  }


  @Watch('value')
  public watchValue(newValue) {
    this.init(newValue)
    this.uploadKey++
  }

  @Watch('id')
  public watchId() {
    this.init(this.value)
  }


  close() {
    this.$bvModal.hide(this.$props.id)
  }


  save() {
    const p = FileApi.putKfzFile(this.data);
    this.handleApiRequest(p, true, true).then((responseDTO: ResponseDTO) => {
      if (responseDTO.data != undefined) {
        this.$emit('input', this.data)
        this.$bvModal.hide(this.$props.id)
        this.$emit('change', this.data)
      }
    });
  }

  archiv() {
    this.$confirm(' Wirklich ' + (this.data.archive ? 'reaktivieren' : 'archivieren') + '?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      this.data.archive = !this.data.archive
      this.save();
    });
  }


  get kfzOptList() {
    return this.kfzList.sort((a, b) => a.licensePlate.localeCompare(b.licensePlate))
  }

  get removePermission() {
    return this.$store.getters.hasPermission(PermissionModul.Kfz_Files, Permission.Delete)
  }
  get archivePermission() {
    return this.$store.getters.hasPermission(PermissionModul.Kfz_Files, Permission.Archive)
  }

  get downloadPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Kfz_Files, (this.data.fileCategories && this.data.fileCategories.find(c => c.confidential))
        ? Permission.Access_Vertraulich : Permission.Access)
  }


}
