import { render, staticRenderFns } from "./MedProduktDateSettingsView.vue?vue&type=template&id=77cdbaad&scoped=true&"
import script from "./MedProduktDateSettingsView.vue?vue&type=script&lang=ts&"
export * from "./MedProduktDateSettingsView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77cdbaad",
  null
  
)

export default component.exports