import { render, staticRenderFns } from "./TaskDataView.vue?vue&type=template&id=24826850&"
import script from "./TaskDataView.vue?vue&type=script&lang=ts&"
export * from "./TaskDataView.vue?vue&type=script&lang=ts&"
import style0 from "./TaskDataView.vue?vue&type=style&index=0&id=24826850&prod&lang=scss&"
import style1 from "quill/dist/quill.snow.css?vue&type=style&index=1&prod&lang=css&"
import style2 from "@/libs/editor/assets/vue2-editor.scss?vue&type=style&index=2&prod&lang=scss&"
import style3 from "@/libs/editor/assets/quill-better-table.css?vue&type=style&index=3&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports