


import {Component} from "vue-property-decorator";
import DateForm from "@/components/DateForm.vue";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {CheckinDTO, GenericError} from "@/model/dto";
import {Checkin} from "@/model/Checkin";
import {CheckinApi} from "@/services/CheckinApi";
import {ApiResponse} from "@/model/AbstractClasses";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import Multiselect from "@/libs/multiselect";
import NumberForm from "@/components/NumberForm.vue";
import CheckinPersonalComponent from "@/views/checkin/components/CheckinPersonalComponent.vue";
import moment from "moment/moment";

@Component({components: {CheckinPersonalComponent, NumberForm, ListItemComponent, DateForm, Multiselect}})
export default class CheckinMask extends mixins<GenericMixin<CheckinDTO, GenericError>>(GenericMixin) {
  error: GenericError = new GenericError()
  data: CheckinDTO = new Checkin()

  kfzOpts = []
  kennungenOpts = []
  dienstOpts = []

  async created() {
    this.data = this.$store.state.checkin.checkin
    if (!this.data.id) this.data.datumStart = moment().format(moment.HTML5_FMT.DATE);
    if (!this.data.id) this.data.zeitStart = moment().format(moment.HTML5_FMT.TIME);

    this.kfzOpts = await this.$store.dispatch("kfz/fetchKfzOu")
    this.kennungenOpts = await this.$store.dispatch("kfz/fetchKennungen")
    this.dienstOpts = await this.$store.dispatch("checkin/fetchDienste")

    this.$root.$emit('loadingDone');
  }

  dienstChanged() {
    Checkin.handleStartzeit(this.data)
  }

  onSubmit(goBack = true) {
    const p = CheckinApi.putCheckin(this.data);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    this.handleApiRequest(p, true).then((data) => {
      if (data instanceof ApiResponse && data.data !== undefined) {
        this.$store.dispatch('checkin/setCheckin', data.data)
        if (goBack) this.goBack()
      }
    })
  }

  goBack() {
    this.$router.push("/checkinlist");
  }
}
