import {apiClient} from './AbstractHTTPService'

import Axios from "axios";
import {ApiResponse, CrudEvent} from "@/model/AbstractClasses";
import {FileDTO, MaterialArtDTO, MaterialAusgabeDTO, MaterialDTO, MaterialRequestDTO, OrgUnitTreeDTO, OutsourcingDTO} from "@/model/dto";
import store from "@/store";
import {Event} from "@/model/Constants";
import {MaterialAusgabeConfirmed} from "@/model/Material";


export abstract class MaterialApi {
    private static usersAxios = Axios.create();

    static async getMaterials(request: MaterialRequestDTO): Promise<any[]> {
        const response = await apiClient.post('/material/materiallist', request);
        return response.data;
    }

    static async getMaterialist(): Promise<MaterialDTO[]> {
        const response = await apiClient.get('/material/materiallist/list');
        return response.data.data;
    }

    static async getMaterialistOu(): Promise<MaterialDTO[]> {
        const response = await apiClient.get('/material/materiallist/oulist');
        return response.data.data;
    }


    static async getMaterialArtList(): Promise<MaterialArtDTO[]> {
        const response = await apiClient.get('/material/materialart');
        return response.data.data;
    }

    static async putMaterialArt(materialArt: MaterialArtDTO): Promise<ApiResponse<MaterialArtDTO>> {
        const response = await apiClient.put('/material/materialart/' + materialArt.id + '/', materialArt);
        store.dispatch("crudEvent", new CrudEvent(Event.materialArtChanged, response.data.data));

        return new ApiResponse(response);
    }

    static async getMaterialArt(id): Promise<ApiResponse<MaterialArtDTO>> {
        const response = await apiClient.get('/material/materialart/' + id);
        return new ApiResponse(response);
    }

    static async archivMaterialArt(id): Promise<any> {
        const response = await apiClient.delete('/material/materialart/' + id);
        store.dispatch("crudEvent", new CrudEvent(Event.materialArtChanged, response.data.data));
        return response.data;
    }

    static async putMaterial(material: MaterialDTO): Promise<ApiResponse<MaterialDTO>> {
        const response = await apiClient.put('/material/materialdata/' + material.id + '/', material);
        const responseDto: ApiResponse<MaterialDTO> = new ApiResponse(response);
        store.dispatch("crudEvent", new CrudEvent(Event.materialChanged, responseDto.data));

        return responseDto;
    }

    static async getMaterial(id): Promise<ApiResponse<MaterialDTO>> {
        const response = await apiClient.get('/material/materialdata/' + id);
        return new ApiResponse(response);
    }

    static async getMaterialTree(id): Promise<OrgUnitTreeDTO[]> {
        const response = await apiClient.get('/material/materialdata/' + id + "/tree");
        return response.data.data;
    }

    static async archivMaterial(id, dto): Promise<any> {
        const response = await apiClient.delete('/material/materialdata/' + id, {data: dto});
        store.dispatch("crudEvent", new CrudEvent(Event.materialChanged, response.data.data));
        return response.data;
    }


    static async updatePhoto(id: number, dto): Promise<FileDTO> {
        const response = await apiClient.put('/material/materialphoto/' + id + '/', dto);
        return response.data.data;
    }

    static async removePhoto(id: number): Promise<any> {
        const response = await apiClient.delete('/material/materialphoto/' + id + '/');
        return response.data;
    }

    static async getMaterialKlasseList(): Promise<MaterialArtDTO[]> {
        const response = await apiClient.get('/material/materialart/klassen/noarchive/ou');
        return response.data.data;
    }

    static async getOutsourcing(): Promise<OutsourcingDTO[]> {
        const response = await apiClient.get('/outsourcing/outsourcinglist/chain/material');
        return response.data.data;
    }


    static async geMaterialAusgabe(id): Promise<ApiResponse<MaterialArtDTO>> {
        const response = await apiClient.get('/material/materialausgabe/' + id);
        return new ApiResponse(response);
    }

    static async putMaterialAusgabe(ausgabe: MaterialAusgabeDTO): Promise<ApiResponse<MaterialAusgabeDTO>> {
        const response = await apiClient.put('/material/materialausgabe/ausgabe', ausgabe);
        return new ApiResponse(response);
    }

    static async setMaterialAusgabeConfirmed(id): Promise<ApiResponse<MaterialAusgabeConfirmed>> {
        const confirmedDTO = new MaterialAusgabeConfirmed()
        confirmedDTO.id = id
        confirmedDTO.empfangConfirmed = true
        const response = await apiClient.put('/material/materialausgabe/confirmed', confirmedDTO);
        return new ApiResponse(response);
    }

    static async getMaterialAusgabenByMaterial(materialId): Promise<MaterialAusgabeDTO[]> {
        const response = await apiClient.get('/material/materialausgabe/material/' + materialId);
        return response.data.data;
    }

    static async getMaterialAusgabenByParent(materialAusgabeId): Promise<MaterialAusgabeDTO[]> {
        const response = await apiClient.get('/material/materialausgabe/childs/' + materialAusgabeId);
        return response.data.data;
    }

    static async getMaterialAusgabenByUser(userId, onlyOpen = false): Promise<MaterialAusgabeDTO[]> {
        const response = await apiClient.get('/material/materialausgabe/user/' + userId + (onlyOpen ? '/open' : ''));
        return response.data.data;
    }
}