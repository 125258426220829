
import {Component, Prop} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {FileLexDTO, FileLexTreeDTO, Permission, PermissionModul} from "@/model/dto";
import {v4 as uuid} from 'uuid';
import jquery from "jquery";
import {func} from '@/scripts/scripts'
import {FileLex} from "@/model/File";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {Route} from "vue-router";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {FileApi} from "@/services/FileApi";
import FileLexEditModal from "@/views/file/FileLexEditModal.vue";
import {Module} from "@/model/Constants";


@Component(
    {
      components: {FileLexEditModal, DatatableButtonHeader},
      watch: {
        '$route'(val: Route) {
          this.init()
        }
      }
    }
)
export default class FileLexList extends mixins<GenericMixin<null, null>>(GenericMixin) {
  table = null
  tableId = 'dTable_' + uuid();

  data: FileLexDTO | null = null
  modalId = 'filelex-edit-modal'

  @Prop() private id;
  foreignTree = false;
  treeDto: FileLexTreeDTO = null;

  getUrl() {
    return '/file/filelex/' + ((this.archive) ? 'archive/' : 'noarchive/') + this.id
  }

  newFile() {
    this.data = new FileLex()
    this.data.tree = this.treeDto
    this.data.passOnChilds = this.treeDto.passOnChilds
    this.$bvModal.show(this.modalId)
  }


  edit(id) {
    if (this.foreignTree) {
      return;
    }

    if (!this.$store.getters.hasPermission(PermissionModul.FILELEX,
        Permission.CRUD)) {
      return;
    }

    let rowId = "#" + id;
    let table = jquery('#' + this.tableId).DataTable();
    let rowdata = table.row(rowId).data();

    if (rowdata.orgUnit.id != this.$store.getters.getLocation.id) {
      this.$alert("Sie haben keine Berechtigung")
      return
    }


    this.data = rowdata
    this.$bvModal.show(this.modalId)
  }

  updateTable() {
    jquery('#' + this.tableId).dataTable().api().ajax.reload();
  }


  mounted() {
    this.init()
  }

  init() {
    this.$root.$emit("loadingStart")
    FileApi.getTree(this.id)
        .then((data) => {
          this.treeDto = data.data
          this.foreignTree = this.treeDto.orgUnit.id != this.$store.getters.getLocation.id
          this.initTable()
        })
        .catch((e) => {
          console.log(e)
          this.$alert("Es ist ein Fehler aufgetreten!")
        })

    this.archive = this.$route.query.archive !== undefined
  }

  initTable() {
    if (this.table) {
      this.unregisterEvents()
      jquery('#' + this.tableId).DataTable().clear().destroy();
      jquery('#' + this.tableId + ' tbody').empty();
      //  jquery('#' + this.tableId + ' thead').empty();
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this

    this.archive = this.$route.query.archive !== undefined
    let myButtons = []
    let contextMenuButtons = []
    this.handleButtons(self, myButtons, contextMenuButtons)


    let table = jquery('#' + this.tableId).DataTable({
      ajax: func.addAuth({
        url: this.getUrl(),
        type: 'GET'
      }),
      order: [[0, 'asc']],
      rowId: "id",
      stateSave: false,
      "rowCallback": function (row, data) {
        if (data && data.orgUnit.id != self.$store.getters.getLocation.id) {
          jquery(row).addClass('foreign-row');
        }
      },
      columns: [
        {
          class: 'cw-200',
          data: 'name'
        },
        {
          class: 'cw-100',
          data: 'beschreibung'
        },
        {
          class: 'cw-75',
          data: 'kategorie.name'
        },
        {
          class: 'text-center cw-100',
          data: null,
          render: function (data, type, row) {
            if (data.typ == 0 && data.wert) {
              return "<a href='" + data.wert + "' target='_blank' title='" + data.wert + "'><i class='fas fa-link text-secondary' style='font-size: 1.5rem;'></i></a>"
            }
            return data.typ == 1 && data.file != null ? '<button file="' + data.file.id + '" class="btn btn-outline-info download" type="button"><i class="fas fa-download" /></button>' : ''
          }
        },
        {
          class: 'cw-100',
          data: 'orgUnit.name'
        },
        {
          class: 'cw-100 text-center',
          data: null,
          render: (data) => data.passOnChilds ? 'X' : ''
        },
        {
          class: 'cw-100',
          data: null,
          render: function (data, type, row) {
            return '<button class="btn btn-outline-info link" type="button"><i class="fas fa-link" /></button>'
          }
        },
      ],
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
      buttons: func.addDefaultButtons(self.foreignTree ? [] : myButtons, true)
    })
    func.datatableInitComplete(table)

    self.initContextMenu(contextMenuButtons)
    self.table = table

    jquery('#' + this.tableId + ' tbody').on('dblclick', 'tr', function () {
      let rowId = jquery(this).attr('id')
      self.edit(rowId)
    })

    jquery('#' + this.tableId + ' tbody').on('click', '.download', function (e) {
      e.preventDefault();
      let rowId = jquery(this).closest('tr').attr('id');
      let myId = jquery(this).attr('file')
      self.download(myId, Module.FileLex)
    });

    jquery('#' + this.tableId + ' tbody').on('click', '.link', function (e) {
      e.preventDefault();
      let rowId = "#" + jquery(this).closest('tr').attr('id');
      const rowData = table.row(rowId).data();
      let text = null
      if (rowData.typ == 0 && rowData.wert) {
        text = rowData.wert
      } else if (rowData.typ == 1 && rowData.file != null && rowData.file.id) {
        const host = process.env.VUE_APP_HOST
        text = host + "/doc/" + encodeURIComponent(self.$CryptoJS.AES.encrypt(""+rowData.file.id, process.env.VUE_APP_CryptoKey).toString())
      } else {
        self.$alert("Es konnte kein Link erstellt werden.", "Fehlgeschlagen", "error")
        return
      }


      if(navigator.clipboard) {
        navigator.clipboard.writeText(text);
        self.$alert(text, "Link in Zwischenablage kopiert")
      }
      else{
        self.$alert(text, "Link")
      }


    });
  }


  handleButtons(self, myButtons, contextMenuButtons) {
    this.addButton(
        PermissionModul.FILELEX, Permission.CRUD,
        {
          titleAttr: 'Hinzufügen',
          className: 'text-secondary bg-primary',
          text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
          action: function (e, dt, node, config) {
            self.newFile()
          },
        }, myButtons)

    this.addButton(
        PermissionModul.FILELEX, Permission.CRUD,
        {
          titleAttr: 'Bearbeiten',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-pen" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            self.edit(id)
          },
        }, myButtons,
        {
          name: "Bearbeiten",
          icon: "fas fa-edit",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.edit(tr.attr('id'))
          }
        }, contextMenuButtons)


    this.addButton(
        PermissionModul.FILELEX, Permission.CRUD,
        {
          titleAttr: 'Archiv ' + (self.archive ? 'verlassen' : ''),
          className: 'text-secondary bg-white',
          text: '<i class="' + (self.archive ? 'fas' : 'fal') + ' fa-archive"/>',
          action: function (e, dt, node, config) {
            self.archiveSwitch()
          },
        }, myButtons,
        {
          name: self.archive ? "Reaktivieren" : "Archivieren",
          icon: self.archive ? "fas fa-boxes-packing" : "fas fa-archive",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.archiv(row.data())
          }
        }, contextMenuButtons
    )
  }

  destroyed() {
    this.unregisterEvents()
  }

  archiveSwitch() {
    let queryParam = {}
    if (!this.archive) {
      queryParam = {archive: 1}
    }
    this.$router.push({path: this.$router.currentRoute.path, query: queryParam})
  }


  archiv(data) {
    if (data.orgUnit.id != this.$store.getters.getLocation.id) {
      this.$alert("Sie haben keine Berechtigung")
      return
    }

    this.$confirm('Sind sie Sicher ?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      FileApi.archiveFilelex(data)
          .then(() => {
            data.archive = !data.archive
            this.updateTable()
          })
          .catch(() => {
            this.$alert("Es ist ein Fehler aufgetreten");
          })
    });
  }
}


