
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {WachenCloudDateDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import DateForm from "@/components/DateForm.vue";

@Component({
  components: {DateForm, Multiselect}
})
export default class CloudDateComponent extends Vue {
  @Prop() private dateTypeOptions: [];
  @Prop() private value: WachenCloudDateDTO;
  @Prop() private error: any;


  data: WachenCloudDateDTO = null
  variante : string;

  async created() {
    this.init(this.value)
    this.variante = "";
  }

  init(newValue) {
    this.data = structuredClone(newValue)
  }
  setType(variante) {
    if(variante == 0) {
      return "Timer-Variante: Datum des letzten Ereignisses (z.B. Prüfung)";
    } else if(variante == 1) {
      return "Vorwarn-Variante: Datum des nächsten Ereignisses (z.B. Prüfung)";
    } else {
      return "";
    }
  }

  changed() {
    this.$emit("input", this.data);
    this.$emit("change", this.data);
    var Typ = this.data.dateType?.intervallTyp;
    this.variante = this.setType(Typ);

  }

  @Watch('value')
  public watchValue(newValue) {
    this.init(newValue)
  }

}
