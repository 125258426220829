
import {Component, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import UserEditView from "@/views/user/UserEditView.vue";
import {Route} from "vue-router";
import UserNavComponent from "@/views/user/components/UserNavComponent.vue";
import {Permission, PermissionModul} from "@/model/dto";


@Component(
    {
      components: {UserNavComponent, UserEditView},
      watch: {
        '$route'(val: Route) {
          this.handleRoute()
        }
      }
    }
)
export default class UserView extends Vue {
  tableRoute = true

  handleRoute() {
    this.tableRoute = this.$route.meta['datatable'];
    if (this.$route.name == 'user' && this.$store.getters.hasPermission(PermissionModul.User, Permission.Overview)) {
      this.$router.push("/user/overview")
    } else if (this.$route.name == 'user' && this.$store.getters.hasPermission(PermissionModul.User_PhoneList, Permission.Overview)) {
      this.$router.push("/user/phonelist")
    }
  }

  created() {
    this.handleRoute()
  }

}
