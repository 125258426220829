
import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {FobiDTO, FobiKlasseDTO, FobiKombiThemaDTO, FobiStatusDTO, GenericError, Permission, PermissionModul, UserResponseDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import DateForm from "@/components/DateForm.vue";
import {FobiThemaStunden, FobiUser, Fortbildung} from "@/model/Fobi";
import LabelComponent from "@/components/LabelComponent.vue";
import FobiThemaStundenComponent from "@/views/fobi/components/FobiThemaStundenComponent.vue";
import FileUpload from "@/components/FileUpload.vue";
import DropzoneMixin from "@/views/DropzoneMixin.vue";
import {DateHelper} from "@/model/Date";
import {Module} from "@/model/Constants";


@Component({
  computed: {
    Module() {
      return Module
    }
  },
  components: {
    FileUpload,
    FobiThemaStundenComponent,
    LabelComponent,
    DateForm, Multiselect
  }
})
export default class FobiDataView extends mixins<GenericMixin<FobiDTO, GenericError>>(GenericMixin,DropzoneMixin) {
  @Prop() value: Fortbildung;
  @Prop() error: GenericError;
  @Prop() hideStatusAndParticipants: boolean;

  data: FobiDTO = this.$props.value;
  fileField = 'nachweise'
  uploadKey = 0

  klassen: FobiKlasseDTO[] = []
  users: UserResponseDTO[] = []
  kombiThemen: FobiKombiThemaDTO[] = []

  statusOpts: FobiStatusDTO[] = []
  usersToAdd = []
  userSetDateEnd = false;

  async created() {
    window.scrollTo(0, 0);
    this.statusOpts = await this.$store.dispatch("fobi/fetchStatus");
    this.statusOpts = this.statusOpts.filter(s => s.id !== Fortbildung.STATUS_CAMPUS)

    this.klassen = await this.$store.dispatch("fobi/fetchKlassen");
    this.users = await this.$store.dispatch("user/fetchUser");
    this.kombiThemen = await this.$store.dispatch("fobi/fetchKombiThemen");
  }

  removeThema(idx) {
    this.data.themen.splice(idx, 1)
  }

  addThema() {
    this.data.themen.push(new FobiThemaStunden());
  }

  usersChanged() {
    this.data.user = this.usersToAdd.map(u => {
      let fobiUser = new FobiUser()
      fobiUser.user = u
      return fobiUser
    })

    this.$emit("input", this.data);
  }

  kombiThemaChanged() {
    if (this.data.kombiThema) {
      let arr = []
      this.data.kombiThema.themen.forEach(thema => {
        let tmpThemaStunden = new FobiThemaStunden();
        tmpThemaStunden.thema = thema.thema
        tmpThemaStunden.stunden = thema.stunden
        arr.push(tmpThemaStunden)
      })

      this.data.themen = arr
    }
  }

  datumTimeChanged() {
    if (this.data.dateStart) {
      const start = DateHelper.getMoment(this.data.dateStart);
      const ende = DateHelper.getMoment(this.data.dateEnd);
      if (start) {
        if (start > ende) {
          this.data.dateEnd = this.data.dateStart
        }
      }
    }
  }

  get workValue() {
    return this.value
  }

  set workValue(val) {
    this.$emit("input", val)
  }

  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
    this.uploadKey++
  }

  get editPermission() {
    if (this.hideStatusAndParticipants) {
      return (this.$store.getters.hasPermission(PermissionModul.Fobi, Permission.Access_Own))
    }

    if (!this.data.id) {
      return (this.$store.getters.hasPermission(PermissionModul.Fobi, Permission.Create))
    }
    return (this.$store.getters.hasPermission(PermissionModul.Fobi, Permission.Update))
  }

}
