
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {PersonenkreisDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import {v4 as uuid} from 'uuid';
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";

const DataProps = Vue.extend({
  props: {
    options: [],
    value: [],
    multi: Boolean,
    hideFilter: Boolean
  }
})
@Component({
  components: {ListItemComponent, Multiselect},
})
export default class PersonenkreisChooser extends DataProps {
  @Prop() label: string
  @Prop() placeholder: string
  @Prop() noFine: boolean
  @Prop({default: 'default'}) modus;

  @Prop() disabled: boolean
  @Prop() error

  selectedValue: PersonenkreisDTO[] | PersonenkreisDTO = []
  multiSelect = true

  uuid = uuid()
  searchTexts = []
  personenkreisOptions = []
  allowed = []

  cbSkill = true
  cbQuali = true
  cbGroup = true
  cbGroupAll = true

  cbPermissiongroup = false
  cbDienste = false
  cbUser = false

  cbKfz = false
  cbKennung = false

  cbFine = false

  noFilter = this.$props.hideFilter ? true : false


  removeElement(id) {
    this.selectedValue = this.selectedValue.filter((e) => e.id != id)
    this.$emit("input", this.selectedValue)
  }

  async created() {
    this.init()
  }

  init() {
    if (this.modus) {
      if (this.modus == "dienste") {
        this.cbFine = false
        this.cbUser = false
        this.cbSkill = false
        this.cbQuali = false
        this.cbGroup = false
        this.cbGroupAll = false
        this.cbPermissiongroup = false
        this.cbDienste = true

        this.allowed = []
      } else if (this.modus == "aufgabe") {
        this.cbFine = false
        this.cbDienste = false
        this.cbSkill = false
        this.cbQuali = false
        this.cbGroupAll = false
        this.cbGroup = true
        this.cbPermissiongroup = true
        this.cbUser = false

        this.allowed = ['cbGroup', 'cbPermissiongroup', 'cbUser']
      } else if (this.modus == "default") {
        this.cbUser = false
        this.cbDienste = false
        this.cbPermissiongroup = false

        this.cbSkill = true
        this.cbQuali = true
        this.cbGroup = true
        this.cbGroupAll = true

        this.allowed = ['cbGroup', 'cbPermissiongroup', 'cbUser', 'cbSkill', 'cbQuali']
      } else if (this.modus == "alluser") {
        this.cbUser = false
        this.cbDienste = false
        this.cbPermissiongroup = true

        this.cbSkill = true
        this.cbQuali = true
        this.cbGroup = true
        this.cbGroupAll = true

        this.allowed = ['cbGroup', 'cbPermissiongroup', 'cbUser', 'cbSkill', 'cbQuali']
      } else if (this.modus == "checkintagebuch") {
        this.cbSkill = false
        this.cbQuali = false
        this.cbGroup = false

        this.cbGroupAll = false

        this.cbDienste = true
        this.cbKennung = true
        this.cbKfz = true

        this.allowed = ['cbDienste', 'cbKennung', 'cbKfz']
      } else if (this.modus == "tagebuch") {
        this.cbSkill = false
        this.cbQuali = false
        this.cbGroup = false
        this.cbGroupAll = false

        this.cbUser = false
        this.cbDienste = true
        this.cbKennung = true
        this.cbKfz = true

        this.allowed = ['cbUser', 'cbDienste', 'cbKennung', 'cbKfz']
      } else if (this.modus == "diva") {
        this.cbFine = false
        this.cbDienste = false
        this.cbSkill = true
        this.cbQuali = true
        this.cbGroupAll = false
        this.cbGroup = false
        this.cbPermissiongroup = false
        this.cbUser = false

        this.allowed = ['cbGroup', 'cbPermissiongroup', 'cbUser', 'cbSkill', 'cbQuali']
      }


    }

    this.selectedValue = Vue.util.extend(this.$props.value)
    this.multiSelect = this.$props.multi
  }

  sendChange(event) {
    this.$emit("input", this.selectedValue)
  }

  @Watch('value')
  public watchValue(newValue) {
    this.selectedValue = Vue.util.extend(this.$props.value)
  }

  @Watch('modus')
  public watchModus(newValue) {
    this.init()
  }


  get getOptions() {
    const lowerPriority = [6, 5, 7]; // 6 = Dienste, 5 = Kfz, 7 = Kennung

    return this.$props.options
        .map((opt: PersonenkreisDTO) => {
          opt['filterText'] = opt.searchText;
          return opt;
        })
        .filter((pK: PersonenkreisDTO) => (!this.cbUser) ? pK.specialTyp != 1 : true)
        .filter((pK: PersonenkreisDTO) => (!this.cbPermissiongroup) ? pK.specialTyp != 8 : true)
        .filter((pK: PersonenkreisDTO) => (!this.cbSkill) ? pK.specialTyp != 3 : true)
        .filter((pK: PersonenkreisDTO) => (!this.cbQuali) ? pK.specialTyp != 4 : true)
        .filter((pK: PersonenkreisDTO) => (!this.cbGroup) ? pK.specialTyp != null : true)
        .filter((pK: PersonenkreisDTO) => (!this.cbGroupAll) ? pK.specialTyp != 2 : true)
        .filter((pK: PersonenkreisDTO) => (!this.cbDienste) ? pK.specialTyp != 6 : true)
        .filter((pK: PersonenkreisDTO) => (!this.cbKfz) ? pK.specialTyp != 5 : true)
        .filter((pK: PersonenkreisDTO) => (!this.cbKennung) ? pK.specialTyp != 7 : true)

        .filter((pK: PersonenkreisDTO) => (!this.cbFine) ? pK.orgUnit == null || pK.orgUnit.id == this.$store.getters.getLocation.id || pK.passOnChilds : true)
        .sort((a: PersonenkreisDTO, b: PersonenkreisDTO) => {
          if (lowerPriority.includes(a.specialTyp) && !lowerPriority.includes(b.specialTyp)) {
            return 1;
          } else if (!lowerPriority.includes(a.specialTyp) && lowerPriority.includes(b.specialTyp)) {
            return -1;
          } else if (lowerPriority.includes(a.specialTyp) && lowerPriority.includes(b.specialTyp)) {
            return 0;
          } else {
            // Alphabetische Sortierung für alle anderen Einträge
            return a.searchText.localeCompare(b.searchText);
          }
        });
  }
}
