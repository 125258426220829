import { render, staticRenderFns } from "./PermissionGroupView.vue?vue&type=template&id=06e7df80&scoped=true&"
import script from "./PermissionGroupView.vue?vue&type=script&lang=ts&"
export * from "./PermissionGroupView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06e7df80",
  null
  
)

export default component.exports