import TaskTables from "@/views/task/TaskTables.vue";
import TaskEditView from "@/views/task/TaskEditView.vue";
import TaskCreateView from "@/views/task/TaskCreateView.vue";
import TaskView from "@/views/task/TaskView.vue";
import TaskTemplateEditView from "@/views/task/TaskTemplateEditView.vue";
import TaskTemplateCreateView from "@/views/task/TaskTemplateCreateView.vue";
import MyTask from "@/views/task/MyTask.vue";
import {Module} from "@/model/Constants";
import {Permission, PermissionModul} from "@/model/dto";


const moduleMeta = {
    module: Module.TASK,
}
export const taskRoutes = [
    {
        path: 'task',
        name: 'task',
        component: TaskView,
        children: [
            {
                path: 'mytask',
                name: 'mytask',
                component: MyTask,
                meta: {
                    datatable: false,
                    loading: true,
                    title: 'Eigene',
                    permission: [PermissionModul.Task, Permission.Overview_Own]
                }
            },
            {
                path: 'mytasktable',
                name: 'mytasktable',
                component: MyTask,
                meta: {
                    permission: [PermissionModul.Task, Permission.Overview_Own]
                }
            },
            {
                path: 'tasktable',
                name: 'tasktable',
                component: TaskTables,
                meta: {
                    datatable: true,
                    loading: true,
                    title: 'Übersicht',
                    permission: [PermissionModul.Task, Permission.Overview],
                    activeRoutes: ['tasktable', 'taskcreate', 'taskedit']
                }
            },
            {
                path: 'create',
                name: 'taskcreate',
                components: {
                    default: TaskTables,
                    CRUD: TaskCreateView
                },
                meta: {
                    loading: true,
                    permission: [PermissionModul.Task, Permission.Create]
                }
            },
            {
                path: 'edit/:id/:idx?',
                name: 'taskedit',
                components: {
                    default: TaskTables,
                    CRUD: TaskEditView

                },
                props: {
                    default: false, CRUD: true
                },
                meta: {
                    loading: true,
                    permission: [PermissionModul.Task, Permission.Overview]
                }
            },
            {
                path: 'template/tasktable',
                name: 'tasktemplatetable',
                component: TaskTables,
                meta: {
                    datatable: true,
                    loading: true,
                    title: 'Vorplanung',
                    permission: [PermissionModul.Task_Plan, Permission.Overview],
                    activeRoutes: ['tasktemplatetable', 'tasktemplatecreate', 'tasktemplateedit']
                }
            },
            {
                path: 'template/create',
                name: 'tasktemplatecreate',
                components: {
                    default: TaskTables,
                    CRUD: TaskTemplateCreateView,
                },
                meta: {
                    permission: [PermissionModul.Task_Plan, Permission.Create]
                }
            },
            {
                path: 'taskpublic',
                name: 'taskpublictable',
                component: TaskTables,
                meta: {
                    datatable: true,
                    loading: true,
                    title: 'Öffentliche',
                    permission: [PermissionModul.Task, Permission.Overview_Public]
                }
            },
            {
                path: 'template/edit/:id',
                name: 'tasktemplateedit',
                components: {
                    default: TaskTables,
                    CRUD: TaskTemplateEditView

                },
                props: {
                    default: false, CRUD: true
                },
                meta: {
                    loading: true,
                    permission: [PermissionModul.Task_Plan, Permission.Overview]
                }
            },
            {
                path: 'form',
                name: 'taskformlist',
                component: TaskTables,
                meta: {
                    datatable: true,
                    loading: true,
                    ...moduleMeta,
                    title: 'Formulare',
                    permission: [PermissionModul.Task_Settings, Permission.Formulare]
                }
            },
        ]
    }
]
