
import {Component} from "vue-property-decorator";
import {Route} from "vue-router";
import Multiselect from "@/libs/multiselect";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {HistoryEvent} from "@/model/Shared";
import {Permission, PermissionModul} from "@/model/dto";
import {mixins} from "vue-class-component";
import NavMixin from "@/views/NavMixin.vue";


@Component(
    {
      components: {Multiselect},
      watch: {
        '$route'(val: Route) {
          this.handleRoute()
        }
      }
    }
)
export default class formNavComponent extends mixins(NavMixin) {
  routeName = null
  navBar = []

  async created() {
    this.fillNavbar(
        ['dienste', 'evd', 'formtable', 'ousettings'],
        this.navBar)
    this.handleRoute()

  }

  handleRoute() {
    this.routeName = this.$route.name
  }

  get typFilterOptions() {
    return [
        ...[{value: null, text: "Alle"}],
        ...this.$store.getters['form/getTypOptions']
    ]
  }

  get typFilter() {
    return this.$store.state.form.typFilter
  }

  set typFilter(val) {
    this.$store.state.form.typFilter = val
    bus.$emit(Event.formFilterChanged)
  }

  history() {
    bus.$emit(Event.history, new HistoryEvent('/ou/history'))
  }

  get historyPermission() {
    return this.$store.getters.hasPermission(PermissionModul.Stammdaten, Permission.History)
  }
}
