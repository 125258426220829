
import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {FobiDTO, FobiKombiThemaDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import DateForm from "@/components/DateForm.vue";
import {FobiKombiThema, FobiThemaStunden} from "@/model/Fobi";
import LabelComponent from "@/components/LabelComponent.vue";
import FobiThemaStundenComponent from "@/views/fobi/components/FobiThemaStundenComponent.vue";
import FileUpload from "@/components/FileUpload.vue";


@Component({
  components: {
    FileUpload,
    FobiThemaStundenComponent,
    LabelComponent,
    DateForm, Multiselect
  }
})
export default class FobiKombiThemaDataView extends mixins<GenericMixin<FobiKombiThemaDTO, FobiKombiThemaDTO>>(GenericMixin) {
  @Prop() value: FobiKombiThema;
  @Prop() error: FobiKombiThema;
  data: FobiDTO = this.$props.value;
  existingFiles = []


  async created() {
    window.scrollTo(0, 0);
  }

  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
  }

  removeThema(idx) {
    this.data.themen.splice(idx, 1)
  }

  addThema() {
    this.data.themen.push(new FobiThemaStunden());
  }
}
