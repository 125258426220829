
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {AbstractGegenstandDTO, EinweisungGegenstandDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import GegenstandListItemComponent from "@/views/shared/components/GegenstandListItemComponent.vue";
import {GegenstandListItem} from "@/model/Shared";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";
import {EinweisungGegenstand} from "@/model/Einweisung";

@Component({
  components: {ListItemComponent, GegenstandListItemComponent, Multiselect}
})
export default class GegenstandComponent extends Vue {
  @Prop() private id: string;
  @Prop() private value: EinweisungGegenstandDTO | AbstractGegenstandDTO;
  @Prop() private error: any;

  @Prop() gegenstandList: []
  gegenstandListAll = []

  data: GegenstandListItem = null
  parsedError = ""
  allEwos = false

  async created() {
    this.init(this.value)
  }

  async allEwosChanged() {
    this.gegenstandListAll = await EinweisungGegenstand.getGegenstandList(this.allEwos)
  }



  init(newValue) {
    if (!this.value) {
      this.data = null
      return;
    }
    if (this.value.kfz) {
      this.data = new GegenstandListItem(this.value.kfz)
    } else if (this.value.medProdukt) {
      this.data = new GegenstandListItem(this.value.medProdukt)
    } else if (this.value.material) {
      this.data = new GegenstandListItem(this.value.material)
    } else if (this.value.materialKlasse) {
      this.data = new GegenstandListItem(this.value.materialKlasse)
    } else if (this.value.kfzCategory) {
      this.data = new GegenstandListItem(this.value.kfzCategory)
    } else {
      this.data = null
    }
  }

  changed() {
    let mappedData: EinweisungGegenstandDTO | AbstractGegenstandDTO = this.value
    mappedData.kfz = null
    mappedData.medProdukt = null
    mappedData.material = null
    mappedData.materialKlasse = null
    mappedData.kfzCategory = null

    if (this.data) {
      if (this.data.type == "kfz") {
        mappedData.kfz = this.data.dto
      } else if (this.data.type == "ewo") {
        mappedData.medProdukt = this.data.dto
      } else if (this.data.type == "material") {
        mappedData.material = this.data.dto
      } else if (this.data.type == "materialArt") {
        mappedData.materialKlasse = this.data.dto
      } else if (this.data.type == "kfzCategory") {
        mappedData.kfzCategory = this.data.dto
      }
    }
    //  this.data.forEach()
    this.$emit("input", mappedData);
    this.$emit("change", mappedData);
  }

  get gegenstandListOptions() {
    return this.allEwos ? this.gegenstandListAll : this.gegenstandList
  }

  @Watch('value')
  public watchValue(newValue) {
    this.init(newValue)
  }

  @Watch('error')
  public watchError(error) {
    if (error['medProdukt']) {
      this.parsedError = error['medProdukt']
    } else if (error['kfz']) {
      this.parsedError = error['kfz']
    } else if (error['material']) {
      this.parsedError = error['material']
    } else {
      this.parsedError = ""
    }
  }
}
