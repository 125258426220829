
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {FobiThemaDTO, FobiThemaStundenDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import NumberForm from "@/components/NumberForm.vue";

@Component({
  components: {NumberForm, Multiselect}
})
export default class FobiThemaStundenComponent extends Vue {
  @Prop() private id: string;
  @Prop() private value: FobiThemaStundenDTO;
  @Prop() private error: any;
  @Prop() private disabled: boolean;
  @Prop({default: false}) private withCampus: boolean;


  data: FobiThemaStundenDTO = null
  themen: FobiThemaDTO[] = []

  async created() {
    this.themen = await this.$store.dispatch("fobi/fetchThemen");
    if (!this.withCampus) {
      this.themen = this.themen.filter(cat => (!cat.courseId))
    }
    this.init(this.value)
  }

  init(newValue) {
    this.data = structuredClone(newValue)
  }

  changed() {
    this.$emit("input", this.data);
    this.$emit("change", this.data);
  }

  @Watch('value')
  public watchValue(newValue) {
    this.init(newValue)
  }

}
