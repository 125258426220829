
import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {EinweisungDTO, Permission, PermissionModul} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import NumberForm from "@/components/NumberForm.vue";
import AdditionalFieldForm from "@/components/AdditionalFieldForm.vue";
import DateForm from "@/components/DateForm.vue";
import {EinweisungGegenstand} from "@/model/Einweisung";
import EinweisungGegenstandComponent from "@/views/einweisung/components/EinweisungGegenstandComponent.vue";
import EinweisungUserComponent from "@/views/einweisung/components/EinweisungUserComponent.vue";
import EinweisungUserSimpleComponent from "@/views/einweisung/components/EinweisungUserSimpleComponent.vue";
import FileUpload from "@/components/FileUpload.vue";
import {Module} from "@/model/Constants";
import DropzoneMixin from "@/views/DropzoneMixin.vue";


@Component({
  computed: {
    Module() {
      return Module
    }
  },
  components: {
    FileUpload,
    EinweisungUserSimpleComponent,
    EinweisungUserComponent,
    EinweisungGegenstandComponent,
    EinweisungGegenstand, DateForm, AdditionalFieldForm, NumberForm, Multiselect
  }
})
export default class EinweisungDataView extends mixins<GenericMixin<EinweisungDTO, EinweisungDTO>>(GenericMixin, DropzoneMixin) {
  @Prop() value: EinweisungDTO;
  @Prop() error: EinweisungDTO;
  data: EinweisungDTO = this.$props.value;

  gegenstandList = []

  fileField = 'nachweise'
  uploadKey = 0

  async created() {
    window.scrollTo(0, 0);

    this.gegenstandList = await EinweisungGegenstand.getGegenstandList()
  }


  removeGegenstand(idx) {
    this.data.gegenstaende.splice(idx, 1)
  }

  addGegenstand() {
    this.data.gegenstaende.push(new EinweisungGegenstand());
  }


  get workValue() {
    return this.value
  }

  set workValue(val) {
    this.$emit("input", val)
  }


  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
    this.init();
    this.uploadKey++
  }

  get editPermission() {
    if (!this.data.id) {
      return (this.$store.getters.hasPermission(PermissionModul.Einweisung, Permission.Create))
    }
    return (this.$store.getters.hasPermission(PermissionModul.Einweisung, Permission.Update))
  }
}
