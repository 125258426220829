
import {Component} from "vue-property-decorator";
import {Route} from "vue-router";
import Multiselect from "@/libs/multiselect";
import DateForm from "@/components/DateForm.vue";
import {mixins} from "vue-class-component";
import NavMixin from "@/views/NavMixin.vue";


@Component(
    {
      components: {DateForm, Multiselect},
      watch: {
        '$route'(val: Route) {
          this.handleRoute()
        }
      }
    }
)
export default class DivaNavComponent extends mixins(NavMixin) {
  routeName = null
  onChangeDebounced = null

  navBar = []
  navBarSettings = []

  async created() {
    this.fillNavbar(
        ['auktionlist', 'divaportal'],
        this.navBar)
    this.fillNavbar(
        ["vivendisync"], this.navBarSettings
    )

    this.handleRoute()
  }

  handleRoute() {
    this.routeName = this.$route.name
  }

}
