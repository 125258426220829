

import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {UserProfile, UserQualification} from "@/model/User";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import moment from "moment/moment";
import {Permission, PermissionModul, QualificationDTO, ResponseDTO, UserQualificationDTO} from "@/model/dto";
import {UsersApi} from "@/services/UserApi";
import Multiselect from '@/libs/multiselect'
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {ObjectEvent} from "@/model/AbstractClasses";
import DateForm from "@/components/DateForm.vue";
import ListItemComponent from "@/views/shared/components/ListItemComponent.vue";

const DataProps = Vue.extend({
  props: {
    value: UserProfile,
  }
})


@Component({
  components: {ListItemComponent, DateForm, Multiselect}
})
export default class UserQualificationView extends mixins<GenericMixin<UserQualification, UserQualification>>(GenericMixin, DataProps) {
  @Prop({default: false}) readOnly;

  data: UserQualificationDTO = new UserQualification()
  error: UserQualificationDTO = new UserQualification()



  showUserQualiModal = false
  user = this.$props.value;
  userQualis: UserQualificationDTO[] = []
  qualis: QualificationDTO[] = []


  showAddUserQualiForm = false

  async created() {
    this.$root.$emit('loadingStart')
    this.qualis = await this.$store.dispatch("user/fetchQualis");
    this.userQualis = await UsersApi.getQualificationOfUser(this.user.id)
    this.$root.$emit('loadingDone')
  }

  editUserQuali(userQuali: UserQualificationDTO) {
    this.data = structuredClone(userQuali)
    this.showUserQualiModal = true
  }

  newUserQuali() {
    this.data = new UserQualification()
    this.showUserQualiModal = true
  }

  saveUserQualification() {
    const p = UsersApi.putUserQualification(this.user.id, this.data);
    this.handleApiRequest(p, true, true).then((data: ResponseDTO) => {
      if (data.data != undefined) {
        this.showUserQualiModal = false
        this.userQualis = this.userQualis.filter((uQ) => {
          return data.data.id !== uQ.id
        })
        this.userQualis.push(data.data)


        this.sendMaxQualiEvent()
      }
    });
  }

  removeUserQuali(uQ: UserQualificationDTO) {
    this.$confirm(' Wirklich löschen?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      UsersApi.removeUserQualification(uQ.id).then(() => {
        this.userQualis = this.userQualis.filter((tmp) => {
          return tmp.id != uQ.id
        })
        this.sendMaxQualiEvent()
      }).catch(() => {
        this.$alert('Es ist ein unerwarteter Fehler aufgetreten!')
      })
    });
  }

  sendMaxQualiEvent() {
    UsersApi.getMaxQualificationOfUser(this.user.id).then((q) => {
      bus.$emit(Event.userQualificationChanged, new ObjectEvent(this.user.id, q));
    })
  }

  get qualiList() {
    return this.userQualis.sort((a: UserQualificationDTO, b: UserQualificationDTO) => {
      return a.statusNumeric <= b.statusNumeric ? 1 : -1
    }).map((uQ: UserQualificationDTO) => {
      if (uQ.validFrom) uQ.validFromTxt = moment(uQ.validFrom).format("DD.MM.YYYY")
      if (uQ.validTo) uQ.validToTxt = moment(uQ.validTo).format("DD.MM.YYYY")
      return uQ;
    });
  }

  @Watch('value')
  public watchValue(newValue) {
    this.user = newValue;
  }

  get editPermission() {
    return this.$store.getters.hasPermission(PermissionModul.User, Permission.Update)
  }
}
