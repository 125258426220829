
import {Component, Vue} from "vue-property-decorator";
import '@/scripts/dataTables_German'
import {Route} from "vue-router";
import {bus} from "@/main";
import {Event} from "@/model/Constants";
import {ObjectEvent} from "@/model/AbstractClasses";
import FormNavComponent from "@/views/form/components/FormNavComponent.vue";
import {FormApi} from "@/services/FormApi";
import {Permission, PermissionModul} from "@/model/dto";


@Component(
    {
      components: {FormNavComponent},
      watch: {
        '$route'(val: Route) {
          this.handleRoute(val)
        }
      }
    }
)
export default class FormView extends Vue {

  tableRoute = true

  handleRoute(route: Route) {
    this.tableRoute = this.$route.meta['datatable'];
    if (this.$route.name == 'form') {
      if  (this.$store.getters.hasPermission(PermissionModul.Stammdaten, Permission.EVD)) {
        this.$router.push("/form/evd")
      } else if (this.$store.getters.hasPermission(PermissionModul.Stammdaten, Permission.CRUD)) {
        this.$router.push("/form/dienste")
      }
    }
  }

  async created() {
    this.handleRoute(this.$router.currentRoute)

    await this.$store.dispatch("form/fetchFieldIdentifer")


    bus.$on(Event.formVersionRemoveRequest, (payload: ObjectEvent<any>) => this.removeFormVersion(payload.data))
  }

  destroyed() {
    bus.$off(Event.formVersionRemoveRequest)
  }

  removeFormVersion(data) {
    const typ = data.archive ? 'reaktivieren' : 'archivieren'
    this.$confirm('Wollen Sie diese Formular-Version ID ' + data.id + ' wirklich ' + typ + '?', '', 'question', {
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nein'
    }).then(() => {
      FormApi.removeFormVersion(data.id)
          .then(() => {
            bus.$emit(Event.formVersionArchived, new ObjectEvent(data.id, {id: data.id, archive: true}));
          })
          .catch(() => {
            this.$alert("Es ist ein Fehler aufgetreten");
          })
    });
  }



}
