
import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {EntityTaskTemplateDTO, GenericError} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import LabelComponent from "@/components/LabelComponent.vue";
import FileUpload from "@/components/FileUpload.vue";
import HtmlEditor from "@/components/HtmlEditor.vue";
import OrgUnitSelector from "@/components/OrgUnitSelector.vue";
import DropzoneMixin from "@/views/DropzoneMixin.vue";
import PersonenkreisChooser from "@/components/PersonenkreisChooser.vue";
import {EntityTaskTemplateObject, Task} from "@/model/Task";
import EntityTaskTemplateEntityData from "@/views/task/entity/EntityTaskTemplateEntityData.vue";
import NumberForm from "@/components/NumberForm.vue";
import {Module} from "@/model/Constants";

@Component({
  computed: {
    Module() {
      return Module
    },
    Task() {
      return Task
    }
  },
  components: {
    NumberForm,
    EntityTaskTemplateEntityData,
    PersonenkreisChooser,
    OrgUnitSelector,
    HtmlEditor,
    FileUpload,
    LabelComponent,
    Multiselect
  }
})
export default class EntityTaskTemplateDataView extends mixins<GenericMixin<EntityTaskTemplateDTO, GenericError>>(GenericMixin, DropzoneMixin) {
  @Prop() value: EntityTaskTemplateDTO;
  @Prop() error: GenericError;
  @Prop() modul;
  @Prop({default: false}) foreignTask;

  data: EntityTaskTemplateDTO = this.$props.value;
  uploadKey = 0
  personenkreisOptions = []
  formOptions = []
  scopeOptions = []
  desiRoutines = []

  async created() {
    this.formOptions = await this.$store.dispatch("form/fetchForms")
    this.formOptions = this.formOptions.filter(form => !form.typ || form.typ == this.modul)
    this.scopeOptions = await this.$store.dispatch("hygiene/fetchScopes")
    this.desiRoutines = await this.$store.dispatch("hygiene/fetchDesiRoutines")
    this.personenkreisOptions = await this.$store.dispatch("user/fetchPersonenkreise")
  }

  removeEntity(idx) {
    this.data.entityTasks.splice(idx, 1)
  }

  addEntity() {
    let tag = new EntityTaskTemplateObject();
    this.data.entityTasks.push(tag);
  }

  get isKfz() {
    return this.$route.name.includes("kfz")
  }


  get workValue() {
    return this.value
  }

  set workValue(val) {
    this.$emit("input", val)
  }

  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
    this.uploadKey++
  }
}
