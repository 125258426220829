
import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {FobiZeitraumDTO} from "@/model/dto";
import Multiselect from "@/libs/multiselect";
import DateForm from "@/components/DateForm.vue";
import {FobiZeitraum, FobiZeitraumAusschluss, FobiZeitraumThemaKlasse} from "@/model/Fobi";
import LabelComponent from "@/components/LabelComponent.vue";
import FobiThemaStundenComponent from "@/views/fobi/components/FobiThemaStundenComponent.vue";
import FileUpload from "@/components/FileUpload.vue";
import FobiZeitraumThemaKlasseStundenComponent from "@/views/fobi/components/FobiZeitraumThemaKlasseStundenComponent.vue";


@Component({
  components: {
    FobiZeitraumThemaKlasseStundenComponent,
    FileUpload,
    FobiThemaStundenComponent,
    LabelComponent,
    DateForm, Multiselect
  }
})
export default class FobiZeitraumDataView extends mixins<GenericMixin<FobiZeitraumDTO, FobiZeitraumDTO>>(GenericMixin) {
  @Prop() value: FobiZeitraum;
  @Prop() error: FobiZeitraum;
  @Prop() readonly: boolean;

  data: FobiZeitraumDTO = this.$props.value;
  existingFiles = []
  chainZeitraum = []

  userOpts = []

  async created() {
    window.scrollTo(0, 0);

    this.userOpts = [...await this.$store.dispatch("user/fetchUser")];
    this.chainZeitraum = await this.$store.dispatch('fobi/fetchZeitraeume')
    this.chainZeitraum = this.chainZeitraum.filter(cat => (cat.id != this.data.id)).sort((a, b) => a.name.localeCompare(b.name))
  }

  removeThema(idx) {
    this.data.themen.splice(idx, 1)
  }

  addThema() {
    this.data.themen.push(new FobiZeitraumThemaKlasse(1));
  }

  removeGesamt(idx) {
    this.data.gesamt.splice(idx, 1)
  }

  addGesamt() {
    this.data.gesamt.push(new FobiZeitraumThemaKlasse(2));
  }

  removeKlasse(idx) {
    this.data.klassen.splice(idx, 1)
  }

  addKlasse() {
    this.data.klassen.push(new FobiZeitraumThemaKlasse(3));
  }


  removeAusschluss(idx) {
    this.data.ausschluss.splice(idx, 1)
  }

  addAusschluss() {
    this.data.ausschluss.push(new FobiZeitraumAusschluss());
  }


  @Watch('value')
  public watchValue(newValue) {
    this.data = newValue;
  }
}
