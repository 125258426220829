
import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {AdditionalFieldDTO, FormRowDTO, GenericError} from "@/model/dto";
import FormInputField from "@/views/form/components/FormInputField.vue";
import {AdditionalFieldResult} from "@/model/AbstractClasses";
import {Module} from "@/model/Constants";

@Component({
  computed: {
    Module() {
      return Module
    }
  },
  components: {FormInputField}
})
export default class FormInput extends Vue {
  @Prop() value: AdditionalFieldResult[];
  @Prop() error: string | GenericError[];
  @Prop() rows: FormRowDTO[];
  @Prop() readOnly;
  @Prop() moduleId;

  filteredRows = []
  col_arr = [];

  loadingComplete = false
  hashmap = new Map<string, AdditionalFieldResult>();


  async created() {
    this.$root.$emit('loadingStart')
    await this.init()
    this.$root.$emit('loadingDone')
  }

  async init() {
    this.filteredRows = []
    this.loadingComplete = true
    let existingKeys = []
    let addedFields = []

    this.rows.forEach(row => {
      if (row.isDescription || row.isDownload) {
        this.filteredRows.push(row)
        return
      }

      row.fields.forEach(field => {
        const key = this.calcKey(row, field)
        existingKeys.push(key)

        if (!this.hashmap.has(key)) {
          this.hashmap.set(key, this.createOrAdd(field));
        }
      })

      //Filtere felder, wenn IDENTIFIER bereits verwendet
      let filteredRow = structuredClone(row)
      filteredRow.fields = filteredRow.fields.filter(field => !addedFields.includes(this.calcKey(row, field)))
      filteredRow.fields.forEach((field: AdditionalFieldDTO) => addedFields.push(this.calcKey(row, field)))
      //console.log(filteredRow.fields)
      this.filteredRows.push(filteredRow)
    })

    //Entferen Feld-Objekte, wenn IDENTIFIER nicht mehr vorhanden
    this.hashmap.forEach((value, key: string) => {
      if (!existingKeys.includes(key)) {
        this.hashmap.delete(key)
      }
    });

    this.input() //sende an parent
  }

  get getError() {
    return this.error
  }


  calcKey(row, field) {
    if (!field.identifier) {
      return field.name + "_" + field.tempId;
    }
    return field.identifier;
  }

  createOrAdd(field: AdditionalFieldDTO) {
    if (this.value) {
      const found = this.value.find(addField => addField.identifier == field.identifier)
      if (found) return found;
    }

    const result = new AdditionalFieldResult()
    result.identifier = field.identifier
    result.fieldTyp = field.fieldTyp
    return result
  }

  input() {
    this.$emit("input", Array.from(this.hashmap.values()));
  }

  calcStyle(row) {
    let arr = []
    if (row.lineTop) {
      arr.push("padding-top: 1rem; padding-bottom: 1rem; border-top: 1px solid " + row.lineTop);
    }
    if (row.lineBottom) {
      arr.push("padding-bottom: 1rem; border-bottom: 1px solid " + row.lineBottom);
    }

    return arr.join("; ");
  }

  cleanHtml(value) {
    return this.$sanitize(value)
  }
  getSizeLabel(number) {
    const sizeMapping = new Map([
      [0, "default"],
      [1, "groß"],
      [2, "default"],
      [3, "klein"],
      [4, "klein"],
      [5, "mittel"],
      [6, "klein"],
      [7, "klein"],
      [8, "groß"],
      [20, "groß"],
      [21, "groß"],
      [22, "groß"],
      [23, "groß"],
      [24, "mittel"],
      [25, "klein"],
      [26, "klein"],
      [27, "groß"],
      [30, "groß"]
    ]);
    return sizeMapping.get(number) || "Unbekannt";
  }

  rowClass(row, field) {
    if (row.fillRow) {
      console.log(field);
      return 'col-12'
    } if(this.getSizeLabel(field.fieldTyp ) == "klein") {
      return 'col-12 col-sm-6 col-md-4 col-lg-2 col-xl-2'
    } if(this.getSizeLabel(field.fieldTyp ) == "mittel") {
      return 'col-12 col-sm-6 col-md-4 col-lg-3 col-xl-3'
    } if(this.getSizeLabel(field.fieldTyp ) == 'groß') {
      return 'col-12 col-md-6 col-lg-4 col-xl-4'
    } else {
      return 'col-12 col-md-6 col-lg-6 col-xl-6'
    }
  }

  @Watch('rows')
  public watchRows(newValue) {
    this.init();
  }


}
