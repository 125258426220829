
import {Component, Prop, Watch} from 'vue-property-decorator';
import {FileDTO, GenericError, MedProduktDateDTO, ResponseDTO} from "@/model/dto";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";
import {MedProduktHelper} from "@/model/MedProdukt";
import {DateApi} from "@/services/DateApi";
import {DateHelper, MedProduktDate} from "@/model/Date";
import LabelComponent from "@/components/LabelComponent.vue";
import DateForm from "@/components/DateForm.vue";
import DropzoneMixin from "@/views/DropzoneMixin.vue";
import FileUpload from "@/components/FileUpload.vue";
import {Module} from "@/model/Constants";

@Component({
  computed: {
    Module() {
      return Module
    },
    DateHelper() {
      return DateHelper
    }
  },
  components: {FileUpload, DateForm, LabelComponent}
})
export default class MedProduktDateEditModal extends mixins<GenericMixin<MedProduktDateDTO, GenericError>>(GenericMixin, DropzoneMixin) {
  data: MedProduktDateDTO = new MedProduktDate()
  error: GenericError = new GenericError()
  @Prop() private value!: MedProduktDateDTO;
  @Prop() private id;
  helper = MedProduktHelper
  uploadKey = 0
  fileSimple = true
  fileField = 'file'
  dateFiles: FileDTO[] = []

  async created() {
    if (this.$props.value != null) {
      await this.init(this.$props.value)
    }
  }

  async init(newValue) {
    this.dateFiles = []
    this.error = new GenericError()

    if (this.data != null) {
      if (newValue.dateTyp && newValue.dateTyp.fileCategory) {
        if (newValue.childs && newValue.childs.length > 0) {
          newValue.childs.forEach((child) => {
            DateApi.getFilesByMedProduktDate(child.id).then((tmp) => {
              this.dateFiles = [...this.dateFiles, ...tmp]
              this.dateFiles = [...new Map(this.dateFiles.map((item) => [item["id"], item])).values()];
            })
          })
        } else {
          this.dateFiles = await DateApi.getFilesByMedProduktDate(newValue.id)
        }
      }

      this.data = structuredClone(newValue)
    } else {
      this.data = new MedProduktDate()
    }
  }

  get getDateTypIntervall() {
    const type = this.data.dateTyp || this.data.ewmpgDateTyp;
    if (type && type.intervallTyp !== null) {
      return type.intervallTyp === 0
          ? "Timer-Variante: Datum des letzten Ereignisses (z.B. Prüfung)"
          : "Vorwarn-Variante: Datum des nächsten Ereignisses (z.B. Prüfung)";
    }
    return "";
  }


  @Watch('value')
  public watchValue(newValue) {
    this.init(newValue)
    this.uploadKey++
  }

  @Watch('id')
  public watchId() {
    this.init(this.value)
  }


  close() {
    this.$bvModal.hide(this.id)
  }

  checkAndSave() {
    const dateType = this.data.dateTyp || this.data.ewmpgDateTyp;
    const dateToCheck = DateHelper.getSqlDate(this.data.date)

    if (dateType && dateType.intervallTyp === 1 && dateToCheck) {
      const selectedDate = new Date(dateToCheck); // Format: 'YYYY-MM-DD'
      const today = new Date();
      if (selectedDate <= today) {
        this.$confirm(
            'Achtung, dieser Termin ist eine Vorwarn-Termin. Sie haben ein Datum in der Gegenwart oder Vergangenheit erfasst. Bitte prüfen Sie das Datum',
            DateHelper.parseDate(dateToCheck),
            'question',
            {
              confirmButtonText: 'geprüft',
              cancelButtonText: 'prüfen'
            }
        ).then(() => {
          this.save();
        });
      } else {
        this.save();
      }
    } else {
      this.save();
    }
  }


  save() {
    const p = DateApi.putMedProduktDate(this.data);
    this.handleApiRequest(p, true, true).then((responseDTO: ResponseDTO) => {
      if (responseDTO.data != undefined) {
        this.$emit('input', this.data)
        this.close()
        this.$emit('change', this.data)
      }
    });
  }

  get workValue() {
    return this.data
  }

  set workValue(val) {
    this.$emit("input", this.data)
  }
}
