
import {Component, Prop, Vue} from "vue-property-decorator";
import {MedProduktHelper} from "@/model/MedProdukt";
import {EwoDTO, KfzResponseDTO, MaterialDTO, MedProduktDTO, OrgUnitDTO, PersonenkreisDTO} from "@/model/dto";
import kfz from "../../../store/modules/kfz";
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'


@Component(
    {
      computed: {
        kfz() {
          return kfz
        }
      },
      components: {ListItemComponent, FontAwesomeIcon},
    }
)
export default class ListItemComponent extends Vue {
  @Prop() private id: string;
  @Prop() private dto: any;
  @Prop() private close: boolean;
  @Prop() private mixed: boolean;
  @Prop() private hideLocation: boolean;
  @Prop() private box: boolean;
  @Prop() private ou: OrgUnitDTO;

  medProduktHelper = MedProduktHelper

  removeElement() {
    this.$emit("remove", this.id ? this.id : this.dto.id)
  }

  get kfzDto(): KfzResponseDTO {
    return this.dto as KfzResponseDTO
  }

  get ewoDto(): EwoDTO {
    return this.dto as EwoDTO
  }

  get materialDto(): MaterialDTO {
    return this.dto as MaterialDTO
  }

  get medProduktDto(): MedProduktDTO {
    return this.dto as MedProduktDTO
  }

  get personenkreisDto(): PersonenkreisDTO {
    return this.dto as PersonenkreisDTO
  }
}
