
import {Component, Prop, Watch} from "vue-property-decorator";
import {mixins} from "vue-class-component";
import GenericMixin from "@/views/GenericMixin.vue";

import jquery from "jquery";

import {v4 as uuid} from 'uuid';
import '@/scripts/dataTables_German'
import {func} from '@/scripts/scripts'
import {Route} from "vue-router";
import {Event, Module} from "@/model/Constants";
import {bus} from "@/main";
import DatatableButtonHeader from "@/components/DatatableButtonHeader.vue";
import {ReportRequest} from "@/model/Emeld";
import {EmeldApi} from "@/services/EmeldApi";
import {AdditionalFieldHelper, HistoryEvent, ListItemHelper} from "@/model/Shared";
import {DateHelper} from "@/model/Date";
import {_} from 'vue-underscore';
import {Permission, PermissionModul} from "@/model/dto";


@Component(
    {
      components: {DatatableButtonHeader},
      watch: {
        '$route'(val: Route) {
          if (this.standalone) {
            if (val.name.includes("reportlist")) {
              this.table.fixedHeader.adjust();
              this.table.fixedHeader.enable()
              this.$root.$emit('loadingDone')

              let fromRoute = this.$store.state.route.from
              if (fromRoute.name.includes("reportlist")) {
                this.init()
              }
            } else {
              this.table.fixedHeader.disable()
            }
          }
        },
        '$route.query.archive'(val) {
          if (this.standalone) {
            if (this.$route.query.archive != this.archive) this.init()
          }
        }
      }
    }
)
export default class ReportListServersideView extends mixins<GenericMixin<null, null>>(GenericMixin) {
  @Prop({default: true}) standalone;
  @Prop({default: () => new ReportRequest()}) request: ReportRequest;


  data = null;
  error = null;
  tableId = 'dTable_' + uuid();
  archive = false
  key = 0
  table = null
  moduleId = Module.REPORT
  windowWith = null;
  thresholdExceeded = true;

  helper = ListItemHelper

  processing = false;

  overviewPermission = Permission.Overview

  clb = () => {
    this.$root.$emit("loadingStart")
    this.$route.params.externalParams = null
    this.init()
  }
  onChangeDebounced = _.debounce(() => {
    this.init()
  }, 1500)
  onResizeDebounced = _.debounce(() => {
    this.init();
  }, 500);

  async mounted() {
    if (this.standalone) {
      bus.$on(Event.emeldReportFilterChanged, this.clb)
      this.registerEvents([Event.emeldReportCreated, Event.emeldReportArchived, Event.emeldReportChanged]);
    }

    this.checkWith(false)
    await this.init();

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this
    jquery('#' + this.tableId).on('dblclick', 'tr', function (e) {
      if (e.target && e.target.className && e.target.className.includes('dt-control')) {
        return;
      }
      let rowId = jquery(this).attr('id')
      if (!rowId) return;
      self.edit(rowId)
    })

  }




  async init() {
    if (this.processing) return;
    this.processing = true

    if (this.standalone) {
      this.archive = this.$route.query.archive !== undefined
    } else {
      this.archive = false
    }

    if (this.table) {
      jquery('#' + this.tableId).DataTable().clear().destroy();
      jquery('#' + this.tableId + ' tbody').empty();
      jquery('#' + this.tableId + ' thead').empty();
    }

    let request = null
    if (this.$route.params && this.$route.params.externalParams) {
      request = structuredClone(this.$route.params.externalParams)
      request.groupCount = false
      request.countOnly = false
      request.classId = request.klasse ? request.klasse.id : null
      this.$store.state.emeld.requestFilter.dateTo = null
      this.$store.state.emeld.requestFilter.dateFrom = null
      this.$store.state.emeld.requestFilter.klasse = request.klasse
      this.$store.state.emeld.requestFilter.level = null
      this.$store.state.emeld.requestFilter.doneUndone = 0
      this.$route.params.externalParams = null

    } else if (this.standalone) {
      request = this.$store.state.emeld.requestFilter.formDataToRequest()
      request.ouId = this.$store.getters.getLocation.id
    } else {
      request = this.request.formDataToRequest()
      request.ouId = this.$store.getters.getLocation.id
    }

    if (this.$router.currentRoute.name.includes('reportlistown')) {
      this.overviewPermission = Permission.Overview_Own
      request.ouChain = false
      request.creatorId = this.$store.getters.getUser.id
    } else {
      this.overviewPermission = Permission.Overview
    }

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    let self = this
    let myButtons = []
    let contextMenuButtons = []
    this.handleButtons(self, myButtons, contextMenuButtons)

    //request.includeOpen = true

    this.data = await EmeldApi.getReports(request);
    const fixCols = [
      {
        class: '',
        title: 'ID',
        data: 'id',
        visible: true
      },
      {
        class: '',
        title: 'Ersteller',
        data: 'creator',
        render: function (d, type, row) {
          return d ? d.formatted : "";
        }
      },
      {
        class: '',
        title: 'Ergeignisdatum',
        data: 'ereignisDatum',
        render: function (d, type, row) {
          return DateHelper.parseDate(d);
        }
      },
      {
        class: '',
        title: 'Erstelldatum',
        data: 'createTS',
        render: function (d, type, row) {
          return DateHelper.parseDate(d);
        }
      },
      {
        class: 'text-center',
        title: 'Status',
        data: 'done',
        render: function (d, type, row) {
          return d ? "<i title='Erledigt' class='fas fa-xl fa-check-circle text-success text-center p-0'></i>" : "<i title='Erfasst' class='fas fa-xl fa-layer-plus text-secondary text-center p-0 erfasst'></i>";
        }
      },
      {
        class: 'zustaendigkeit',
        title: 'Zuständigkeit',
        data: 'zustaendigkeit',
        render: function (d, type, row) {
          return d ? self.helper.getTag(d) : "";
        }
      },
      {
        class: '',
        title: 'Kategorie I',
        data: 'level1.name'
      },
      {
        class: '',
        title: 'Kategorie II',
        data: 'level2.name'
      },
      {
        class: '',
        title: 'Kategorie III',
        data: 'level3.name'
      },
      {
        class: '',
        title: 'Kategorie IV',
        data: 'level4.name'
      },
      {
        class: '',
        title: 'Kategorie V',
        data: 'level5.name'
      },
      {
        class: '',
        title: 'Standort',
        data: 'orgUnit.name'
      },
    ]


    const cols = this.data["cols"].length ? [
      ...fixCols,
      ...AdditionalFieldHelper.getTableColumns(this.data.cols)
    ] : fixCols;
    const rows = this.data["rows"];

    let tableButtons = !self.standalone ? [] : func.addDefaultButtons(myButtons)

    let tableOptions = {
      fixedHeader: {
        header: (self.standalone),
        headerOffset: this.changeOffset(),

      },
      stateSave: false,
      responsive: false,
      rowId: "id",
      order: [[0, "asc"]],
      columns: cols,
      data: rows,
      buttons: tableButtons,
      initComplete: function (settings, json) {
        self.$root.$emit("loadingDone")
      },
    }

    if (!this.standalone) {
      tableOptions.dom = 'frtip'
    }

    let table = jquery('#' + this.tableId).DataTable(tableOptions)
    func.datatableInitComplete(table, 0, self, self.standalone)


    self.table = table
    if (self.standalone) self.initContextMenu(contextMenuButtons)

    this.processing = false

  }

  handleButtons(self, myButtons, contextMenuButtons) {
    this.addButton(
        PermissionModul.Emeld, Permission.Create,
        {
          titleAttr: 'Hinzufügen',
          className: 'text-secondary bg-primary',
          text: '<i class="fas fa-plus fa-xs" style="color: white"/>',
          action: function (e, dt, node, config) {
            self.create()
          },
        }, myButtons)

    this.addButton(
        PermissionModul.Emeld, self.overviewPermission,
        {
          titleAttr: 'Bearbeiten',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-pen" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            self.edit(id)
          },
        }, myButtons,
        {
          name: "Bearbeiten",
          icon: "fas fa-edit",
          callback: function (key, opt) {
            const tr = opt.$trigger
            var row = self.table.row(tr)
            self.edit(tr.attr('id'))
          }
        }, contextMenuButtons)

    this.addButton(
        PermissionModul.Emeld, Permission.History,
        {
          titleAttr: 'Zeilenhistorie',
          className: 'text-secondary bg-white',
          text: '<i class="fas fa-clock-rotate-left" />',
          extend: 'selectedSingle',
          action: function (e, dt, node, config) {
            let id = self.table.row({selected: true}).id()
            bus.$emit(Event.history, new HistoryEvent('/emeld/report', id))
          },
        }, myButtons,
        {
          name: "Historie",
          icon: "fas fa-clock-rotate-left",
          callback: function (key, opt) {
            const tr = opt.$trigger
            bus.$emit(Event.history, new HistoryEvent('/emeld/report', tr.attr('id')))
          }
        }, contextMenuButtons)

  }


  create() {
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.fixedHeader.disable()
    this.$router.push({path: "/emeld/report/create/", query: queryParam})
  }

  async edit(id) {
    if (!this.standalone) {
      let data = this.table.row("#" + id).data();
      this.$emit("report", data)
      return;
    }

    if (!this.$store.getters.hasPermission(PermissionModul.Emeld,
        this.overviewPermission)) {
      return;
    }

    EmeldApi.checkUpdateAccess(id)
        .then((res) => {
          this.editReport(id)
        })
        .catch((res) => {
          this.$alert("Sie haben keine Rechte zum Bearbeiten dieser Meldung!", "Keine Berechtigung", "error")
        })
  }

  editReport(id) {
    this.table.fixedHeader.disable()
    let queryParam = {}
    if (this.archive) {
      queryParam = {archive: 1}
    }
    this.table.row("#" + id).child.hide()
    this.$router.push({path: "/emeld/report/edit/" + id, query: queryParam})
  }

  changeOffset() {
    if(this.thresholdExceeded) {
      return this.$DesktopHeaderOffset;
    } else {
      return this.$MobilHeaderOffset;
    }
  }


  destroyed() {
    bus.$off(Event.emeldReportFilterChanged, this.clb)
    this.table.fixedHeader.disable()
    jquery.contextMenu('destroy')

    this.unregisterEvents()
  }


  @Watch('request', {deep: true})
  public watchValue(newValue) {
    this.onChangeDebounced()

  }
  @Watch('$screen.width')
  onWidthChange() {
    this.checkWith()
  }

  checkWith(reload= true) {
    this.windowWith = this.$screen.width;
    const exceedsThreshold = this.windowWith > this.$navBarUmbruch;

    if (exceedsThreshold != this.thresholdExceeded) {
      this.thresholdExceeded = exceedsThreshold;
      if (reload) this.onResizeDebounced();
    }
  }
}

